import React from 'react'
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import AreYouSureModal from '../../../components/AreYouSureModal';

const TextbookPageSetupButton = ({ 
    course,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [textbookTotalPage, setTextbookTotalPage] = React.useState(course.textbook_total_page ? course.textbook_total_page : "");

    const handleOnChange = (e) => {
        const newValue = e.target.value;
        setTextbookTotalPage(newValue);
    };

    React.useEffect(() => {
        if (course && course.textbook_total_page) {
            setTextbookTotalPage(course.textbook_total_page);
        }
    }, [course]);

    const handleSave = async () => {
        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        if (isNaN(textbookTotalPage) || textbookTotalPage === "") {
            enqueueSnackbar("Textbook total page must be a number.", { variant: "error" });
            return;
        }

        try {
            // emit to server
            socket.emit("put.TextbookTotalPageSetup", {
                course_id: course.id,
                textbook_total_page: textbookTotalPage,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save textbook total page at the moment.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.greenAccent[900],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.greenAccent[800],
                    },
                }}
            >
                Set Up Total Pages
            </Button>
            <Modal
                aria-labelledby="total-pages-setup-modal"
                aria-describedby="total-pages-setup-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Set Up Total Pages of Textbook for ({course.code}) {course.course_title}
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <TextField
                                label="Total Pages of Textbook"
                                variant="outlined"
                                type='number'
                                value={textbookTotalPage}
                                onChange={(e) => handleOnChange(e)}
                                sx={{ width: 600, mt: 2 }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => setAreYouSureModalOpen(true)}
                            >
                                Save
                            </Button>
                            <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            />
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default TextbookPageSetupButton