import React from 'react'
import Popover from '@mui/material/Popover';
import GoToClassRoomButton from '../../attendance/vertIcon/GoToClassRoomButton';
import { Button, Box, Tooltip } from '@mui/material';
import GoToOneDriveButton from './GoToOneDriveButton';
import OneDriveSetupButton from './OneDriveSetupButton';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import Axios from 'axios';
import TextbookPageSetupButton from './TextbookPageSetupButton';
import CourseMemoButtonModal from './CourseMemoButtonModal';
import CourseCancelMemoButtonModal from './CourseCancelMemoButtonModal';


const CourseButtonMenu = ({
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    classLogUpdatedEventId,
    setClassLogUpdatedEventId,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hasProblem, setHasProblem] = React.useState(false); // for course pace problem
    const [totalPageNotSet, setTotalPageNotSet] = React.useState(false);

    React.useEffect(() => {
        if (event.course && event.course.textbook_total_page !== null && event.course.textbook_total_page !== 0 && !event.course.code.includes("W-")) {
            // writing competition course does not need to calculate course pace
            setTotalPageNotSet(false);
        }
    }, [event, event.course]);

    async function hasProblemWithCoursePace() {
        if (event.course.textbook_total_page === null || event.course.textbook_total_page === 0) {
            setTotalPageNotSet(true);
            return;
        }
        
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${event.course.uuid}/events/${event.id}/pace`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((res) => {
            if (res.data.hasProblem === true) {
                setHasProblem(true);
            } else {
                setHasProblem(false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    React.useEffect(() => {

        if (event && !event.is_canceled && event.course && !event.course.code.includes("W-")) {
            // writing competition course does not need to calculate course pace
            hasProblemWithCoursePace();
        }
    }, [event, event.course]);

    React.useEffect(() => {
        if (classLogUpdatedEventId === event.id) {
            hasProblemWithCoursePace();
            setClassLogUpdatedEventId(null);
        }
    }, [classLogUpdatedEventId]);

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);

        // need to emit to server to let other users know that this user is viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: true,
        });
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);

        // need to emit to server to let other users know that this user is no longer viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: false,
        });
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "8px";
        } else {
            return "12px";
        }
    }

    return (<>
        {hasProblem && !event.is_canceled_int > 0 &&
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 1,
                }}
            >
                <Tooltip title="Check if the course is on pace" arrow placement='top'>
                    <AssignmentLateOutlinedIcon color='warning' fontSize='medium' />
                </Tooltip>
            </Box>
        }
        {totalPageNotSet &&
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 1,
                }}
            >
                <Tooltip title="Total page of the textbook is not set" arrow placement='top'>
                    <AssignmentLateOutlinedIcon color='error' fontSize='medium' />
                </Tooltip>
            </Box>
        }
        <Button
            sx={{
                fontWeight: 800,
                fontSize: getFontSize(event.start_end_diff),
                textAlign: "center",
                width: "100%",
                height: "100%",
                color: event.is_canceled_int ? "red !important" : "#0470C0 !important",
                padding: (!event.start_end_diff || event.start_end_diff === "1.00") ? "0px" : "6px 8px",
                textTransform: "none",
            }}
            onClick={handleButtonClick}
        >
            {event.course?.course_title}
        </Button>
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    width: "200px",
                    boxShadow: 4,
                }}
            >
                <GoToClassRoomButton course={event.course} />
                <TextbookPageSetupButton
                    course={event.course}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    enqueueSnackbar={enqueueSnackbar}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                {event.course && event.course.one_drive_url !== null ? <GoToOneDriveButton course={event.course} colors={colors} /> : null}
                <OneDriveSetupButton 
                    course={event.course}
                    colors={colors} 
                    handlePopOverClose={handlePopOverClose} 
                    enqueueSnackbar={enqueueSnackbar}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <CourseMemoButtonModal
                    course={event.course}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    enqueueSnackbar={enqueueSnackbar}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <CourseCancelMemoButtonModal
                    course={event.course}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    enqueueSnackbar={enqueueSnackbar}
                    socketConnected={socketConnected}
                    socket={socket}
                />
            </Box>
        </Popover>
    </>)
}
export default CourseButtonMenu