import React from 'react'
import Popover from '@mui/material/Popover';
import { Button, Box, Typography } from '@mui/material';
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import SendMessageButtonModal from '../studentButtonMenu/SendMessageButtonModal';
import Checkbox from '@mui/material/Checkbox';
import TeacherPresentButton from './TeacherPresentButton';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MemoButtonModal from './MemoButtonModal';

const TeacherButtonMenu = ({
    admin_name,
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    teacher_course,
    teacher_course_idx,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    setTeacherForMoreInfo,
    setBackdropOpen,
    handleBackdropClose,
    setCheckedTeacherCourseList,
    checkedTeacherCourseList,
    showCheckBox,
    mobileMessageTemplates,
}) => {
    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const LightTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        )
    )(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: colors.grey[900],
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[5],
                fontSize: 14,
                border: `1px solid ${colors.grey[700]}`,
            },
        })
    );

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        // need to emit to server to let other users know that this user is viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: true,
        });
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        // need to emit to server to let other users know that this user is no longer viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: false,
        });
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    async function handleMoreInfoClick() {
        setBackdropOpen(true);
        try {
            const res = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher_course.teacher.uuid}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setTeacherForMoreInfo(res.data);
        } catch (err) {
            if (err.response.status === 401) {
                handleLogout();
            } else {
                console.error(err);
                enqueueSnackbar("Error: Cannot get student information at the moment.", { variant: "error" });
            }
        }
        handleBackdropClose();
    }

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "10px";
        } else {
            return "12px";
        }
    }

    // function getStudentFontColor(attendance) {
    //     if (event.is_canceled_int || attendance.status === 4) {
    //         return "red !important";
    //     }
    //     if (attendance.status === 2) {
    //         return"#00B050 !important";
    //     }
    //     if (attendance.status !== 3 && attendance.session_number === 1) {
    //         return colors.blueAccent[600] + " !important";
    //     }

    //     return null;
    // }

    function getTeacherName() {
        let teacher_name = "n/a";

        if (!teacher_course || !teacher_course.teacher) {
            return teacher_name;
        }

        if (teacher_course.teacher.first_name && teacher_course.teacher.last_name) {
            teacher_name = teacher_course.teacher.first_name + " " + teacher_course.teacher.last_name;
        } else if (teacher_course.teacher.first_name) {
            teacher_name = teacher_course.teacher.first_name;
        } else if (teacher_course.teacher.last_name) {
            teacher_name = teacher_course.teacher.last_name;
        }

        return teacher_name;
    }

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    zIndex: 1,
                }}
            >
                {teacher_course && teacher_course.memo && teacher_course.memo !== "" && (
                    <LightTooltip 
                        title={
                            teacher_course.memo.split("\n").map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })
                        } 
                        placement="right-start"
                        arrow
                    >
                        <ModeCommentIcon fontSize='small' color='info' />
                    </LightTooltip>
                )}
            </Box>
            {showCheckBox && teacher_course &&
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <Checkbox
                        onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                                setCheckedTeacherCourseList((prev) => {
                                    const _teacher_course = { ...teacher_course };
                                    _teacher_course.event_id = event.id;
                                    return [...prev, _teacher_course];
                                });
                            } else {
                                const newCheckedTeacherCourseList = checkedTeacherCourseList.filter((item) => item.id !== teacher_course.id);
                                setCheckedTeacherCourseList(newCheckedTeacherCourseList);
                            }
                        }}
                        sx={{ 
                            '& .MuiSvgIcon-root': { 
                                fontSize: 20,
                            },
                        }}
                    />
                </Box>
            }
            <Button
                disabled={!teacher_course}
                sx={{
                    fontSize: getFontSize(event.start_end_diff),
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    padding: "0px",
                    color: event.is_canceled_int && "red !important",
                    textTransform: 'none',
                    borderTop: teacher_course_idx > 0 ? `1px solid ${colors.grey[700]}` : "none",
                    borderRadius: "0px",
                    backgroundColor: event.teacher_id && teacher_course && event.teacher_id === teacher_course.teacher_id && "yellow",
                    '&:hover': {
                        // make the background color a little darker
                        backgroundColor: event.teacher_id && teacher_course && event.teacher_id === teacher_course.teacher_id && "#F5F502",
                    },
                }}
                onClick={(e) => handleButtonClick(e)}
            >
                <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                    {getTeacherName()}
                </Typography>
            </Button>
            <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        bgcolor: "background.paper",
                        width: "200px",
                        boxShadow: 4,
                    }}
                >
                    <Button 
                        color='primary'
                        sx={{ 
                            p: 1, 
                            width: "100%",
                            bgcolor: colors.primary[800],
                            borderRadius: "0px",
                        }}
                        onClick={handleMoreInfoClick}
                    >
                        More Info
                    </Button>
                    <TeacherPresentButton
                        zoom_id={zoom_id}
                        date={date}
                        _time={_time}
                        event_idx={event_idx}
                        event={event}
                        enqueueSnackbar={enqueueSnackbar}
                        colors={colors}
                        handlePopOverClose={handlePopOverClose}
                        socketConnected={socketConnected}
                        socket={socket}
                        teacher_course={teacher_course}
                    />
                    <SendMessageButtonModal
                        admin_name={admin_name}
                        userId={userId}
                        zoom_id={zoom_id}
                        date={date}
                        _time={_time}
                        event_idx={event_idx}
                        event={event}
                        teacher_course={teacher_course}
                        enqueueSnackbar={enqueueSnackbar}
                        colors={colors}
                        handlePopOverClose={handlePopOverClose}
                        socket={socket}
                        socketConnected={socketConnected}
                        mobileMessageTemplates={mobileMessageTemplates}
                    />
                    <MemoButtonModal
                        admin_name={admin_name}
                        userId={userId}
                        zoom_id={zoom_id}
                        date={date}
                        _time={_time}
                        event_idx={event_idx}
                        event={event}
                        teacher_course_idx={teacher_course_idx}
                        teacher_course={teacher_course}
                        enqueueSnackbar={enqueueSnackbar}
                        colors={colors}
                        handlePopOverClose={handlePopOverClose}
                        socket={socket}
                        socketConnected={socketConnected}
                    />
                </Box>
            </Popover>
        </Box>
    )
}
export default TeacherButtonMenu;