import React from 'react';
import { Box, TextField, Typography, Button } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import AreYouSureModal from '../../../components/AreYouSureModal';

const _PASSWORD = "aone0819";

function CopyPrevWeekZoomAssignModal({
    admin_name,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    copyPrevWeekZoomAssignOpen,
    handleCopyPrevWeekZoomAssignClose,
    thisWeek,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxHeight: 800,
        overflowY: "auto",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [password, setPassword] = React.useState("");

    const handleCopyPrevWeekZoomAssign = async () => {
        setAreYouSureModalOpen(false);

        if (!socketConnected) {
            enqueueSnackbar("Not connected to the server.", { variant: "error" });
            return;
        }

        try {
            socket.emit("put.EventCopyPrevWeekZoomAssign", { 
                admin_name, 
                thisWeek,
            });

            setPassword("");
            handleCopyPrevWeekZoomAssignClose();
        } catch (error) {
            enqueueSnackbar("Error copying Zoom assignments from previous week.", { variant: "error" });
        }
    };

    return (
        <Modal
            aria-labelledby="copy-prev-week-zoom-assign-modal-title"
            aria-describedby="copy-prev-week-zoom-assign-modal-description"
            open={copyPrevWeekZoomAssignOpen}
            onClose={handleCopyPrevWeekZoomAssignClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={copyPrevWeekZoomAssignOpen}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[450]}>
                        Copy Zoom Assignments from Previous Week
                    </Typography>
                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleCopyPrevWeekZoomAssignClose} />
                    <Box 
                        sx={{ 
                            display: "flex", 
                            flexDirection: "column", 
                            mt: 2,
                            flexWrap: "wrap",
                        }}
                    >
                        <Typography variant="h5" color={colors.grey[700]}>
                            (This will copy the Zoom assignments from the previous week to the week you are currently viewing.)
                        </Typography>
                        <br />
                        <Typography color={colors.primary[450]}>
                            Be aware that:
                        </Typography>
                        <ul style={{ margin: 0 }}>
                            <li>
                                if there are any Zoom assignments in the previous week, they will be overwritten.
                            </li>
                            <li>
                                if a module in the current week is, for example, the first module of the course, meaning it has no previous module, it will remain unchanged.
                            </li>
                            <li>
                                if a previous module of the current week module is not assigned to any Zoom, the current week module will not be assigned to any Zoom.
                            </li>
                        </ul>
                        <TextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{ mt: 4, width: 200, mx: "auto" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                            mt: 4,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ width: 200, mt: 2 }}
                            onClick={() => {
                                if (password !== _PASSWORD) {
                                    enqueueSnackbar("Incorrect password.", { variant: "error" });
                                    return;
                                }
                                setAreYouSureModalOpen(true)
                            }}
                        >
                            Copy
                        </Button>
                        <AreYouSureModal
                            colors={colors}
                            open={areYouSureModalOpen}
                            setOpen={setAreYouSureModalOpen}
                            title="Are you sure?"
                            message="This will overwrite the Zoom assignments in the current week."
                            onConfirm={handleCopyPrevWeekZoomAssign}
                        />
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ width: 200, mt: 2 }}
                            onClick={handleCopyPrevWeekZoomAssignClose}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CopyPrevWeekZoomAssignModal