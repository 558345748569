import React from "react";
import { Box } from "@mui/material";
import MemoOptionsToolbar from "../MemoOptionsToolbar";

const DailyMemoComp = ({
    colors,
    socket,
    dailyMemo,
    userId,
    enqueueSnackbar,
}) => {

    const toolbarRef = React.useRef(null);

    const [memoTextOptionsOpen, setMemoTextOptionsOpen] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(false);
    const [memo, setMemo] = React.useState(dailyMemo.memo);
    const [prevMemo, setPrevMemo] = React.useState(dailyMemo.memo);
    const textFieldRef = React.useRef(null);

    React.useEffect(() => {

        if (dailyMemo && dailyMemo.memo !== prevMemo) {
            setMemo(dailyMemo.memo);
            setPrevMemo(dailyMemo.memo);
        }

    }, [dailyMemo]);

    React.useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.innerHTML = memo;
        }
    }, [memo]);

    React.useEffect(() => {
        const handleClickoutside = (e) => {

            if (textFieldRef.current && !textFieldRef.current.contains(e.target)) {

                if (toolbarRef.current && toolbarRef.current.contains(e.target)) {
                    textFieldRef.current.focus();
                    return;
                }    

                /**
                 * emit to server that this cell is not being edited
                 */
                try {
                    socket.emit("put.activeUsers", {
                        is_daily_memo: true,
                        admin_uuid: userId,
                        active: false,
                        daily_memo_id: dailyMemo.id,
                    });
                } catch (error) {
                    console.error(error);
                    enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
                }

                setMemo(textFieldRef.current.innerHTML);
                setIsEditing(false);
                setMemoTextOptionsOpen(false);

                if (textFieldRef.current && textFieldRef.current.innerHTML !== prevMemo) {


                    try {
                        socket.emit("update.WeeklyDailyMemos", {
                            id: dailyMemo.id,
                            memo: textFieldRef.current.innerHTML,
                        });
                    } catch (error) {
                        console.error(error);
                    }
        
                    // save the text to localStorage for undo
                    const prevWeeklyDailyMemos = localStorage.getItem("prevWeeklyDailyMemos") ? JSON.parse(localStorage.getItem("prevWeeklyDailyMemos")) : [];
                    prevWeeklyDailyMemos.push({
                        id: dailyMemo.id ? dailyMemo.id : null,
                        memo: dailyMemo.memo,
                    });
                    localStorage.setItem("prevWeeklyDailyMemos", JSON.stringify(prevWeeklyDailyMemos));
                }
            }
        };

        document.addEventListener("mousedown", handleClickoutside);

        return () => {
            document.removeEventListener("mousedown", handleClickoutside);
        };
    }, []);
    
    function handleEditing() {
        setIsEditing(true);
        setMemoTextOptionsOpen(true);

        setTimeout(() => {
            if (textFieldRef.current) {
                textFieldRef.current.innerHTML = memo ? memo : "";
            }
        }, 10);

        setTimeout(() => {
            const textarea = textFieldRef.current;
            if (textarea) {

                // select text from a window
                var selectedText = window.getSelection();
            
                // create a range
                var selectedRange = document.createRange();

                var lastNode = textarea.childNodes[textarea.childNodes.length - 1];

                if (lastNode === undefined) {
                    textarea.focus();
                    return;
                }

                var lastNodeLength = lastNode.length;

                let count = 0;
                while (lastNodeLength === undefined) {

                    lastNode = lastNode.childNodes[lastNode.childNodes.length - 1];

                    if (lastNode === undefined) {
                        textarea.focus();
                        return;
                    }

                    lastNodeLength = lastNode.length;
                    count++;

                    if (count === 10) {
                        lastNodeLength = 0;
                        break;
                    }
                }

                // set starting position of the cursor in the texts
                selectedRange.setStart(lastNode, lastNodeLength);

                // collapse the range at boundaries
                selectedRange.collapse(true);

                // remove all ranges
                selectedText.removeAllRanges();

                // add a new range to the selected text
                selectedText.addRange(selectedRange);

                textarea.focus();
            }
        }, 20);

        /**
         * emit to server that this daily memo is being edited
         */
        try {
            socket.emit("put.activeUsers", {
                is_daily_memo: true,
                admin_uuid: userId,
                active: true,
                daily_memo_id: dailyMemo.id,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
        }
    }

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100%",
            }}
        >
            {isEditing ? (
                <div
                    id="outlined-multiline-static"
                    contentEditable="true"
                    ref={textFieldRef}
                    style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "11px",
                        border: "none",
                        resize: "none",
                        lineHeight: "1.2",
                        backgroundColor: "transparent",
                        color: colors.primary[100],
                        overflow: "auto",
                        scrollbarWidth: "3px",
                        scrollbarHeight: "3px",
                        '::WebkitScrollbar': {
                            width: "3px",
                            height: "3px",
                        },
                        outline: "0.5px solid " + colors.primary[100],
                        padding: "5px",
                    }}
                >
                </div>
            ) : (
                <div
                    tabIndex={0}
                    onClick={handleEditing}
                    style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        color: colors.primary[100],
                        fontSize: "11px",
                        lineHeight: "1.2",
                        padding: "5px",
                    }}
                    dangerouslySetInnerHTML={{ __html: memo }}
                >
                </div>
            )}

            {memoTextOptionsOpen && 
                <MemoOptionsToolbar
                    colors={colors}
                    toolbarRef={toolbarRef}
                    isCellMemo={false}
                    isMainMemo={false}
                    setCellColor={null}
                />
            }
        </Box>
    );
};

export default DailyMemoComp;
