import React from 'react'
import Button from '@mui/material/Button';
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
// import { countryPhoneCodes } from './countryPhoneCode';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime, Settings } from 'luxon';
import CheckBox from '@mui/material/Checkbox';
import AreYouSureModal from '../../../components/AreYouSureModal';
import getCountryPhoneCode from '../../../utils/getCountryPhoneCode';

Settings.defaultZone = 'America/New_York';

function SendMessageButtonModal({
    admin_name,
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    attendance,
    teacher_course,
    enqueueSnackbar,
    colors,
    handlePopOverClose,
    socketConnected,
    socket,
    mobileMessageTemplates,
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight: '96%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    
    const [student, setStudent] = React.useState(attendance ? attendance.student : null);
    const [teacher, setTeacher] = React.useState(teacher_course ? teacher_course.teacher : null);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [activeTemplates, setActiveTemplates] = React.useState([]);
    const [messageTemplates, setMessageTemplates] = React.useState([]);
    const [hideAll, setHideAll] = React.useState(false);
    const [messageLength, setMessageLength] = React.useState(0);

    const [isScheduled, setIsScheduled] = React.useState(false);
    const [scheduledDate, setScheduledDate] = React.useState(DateTime.now());
    const [memo, setMemo] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const handleOpen = React.useCallback(() => { setOpen(true); }, []);
    const handleClose = React.useCallback(() => { setOpen(false); handlePopOverClose(); }, [handlePopOverClose]);

    React.useEffect(() => {
        if (attendance) {
            setStudent(attendance.student);
        }
        if (teacher_course) {
            setTeacher(teacher_course.teacher);
        }
    }, [attendance, teacher_course]);

    React.useEffect(() => {
        if (mobileMessageTemplates) {
            setMessageTemplates(mobileMessageTemplates);
        }
    }, [mobileMessageTemplates]);

    const handleMessageTemplateButtonClick = (template) => {
        const message = template.template;
        
        if (activeTemplates.includes(template)) {
            setActiveTemplates(activeTemplates.filter((item) => item !== template));
        } else {
            setActiveTemplates([...activeTemplates, template]);
            setMessage((prev) => {
                if (prev === "") {
                    setMessageLength(message.length);
                    return message;
                }
                setMessageLength(prev.length + message.length + 1);
                return prev + "\n" + message;
            });
        }
    };

    const handleChange = (e, phone) => {
        const _phone = phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");

        if (e.target.checked) {
            setSelectedPhoneNumbers([...selectedPhoneNumbers, _phone]);
        } else {
            setSelectedPhoneNumbers(selectedPhoneNumbers.filter((item) => item !== _phone));
        }
    };

    async function handleSend() {
        if (!socketConnected) {
            enqueueSnackbar("Socket server is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        if (selectedPhoneNumbers.length === 0) {
            enqueueSnackbar("Please select at least one phone number.", { variant: "error" });
            return;
        }

        if (message === "") {
            enqueueSnackbar("Please enter a message.", { variant: "error" });
            return;
        }

        if (message.length > 160) {
            enqueueSnackbar("Message is too long. Please keep it under 160 characters.", { variant: "error" });
            return;
        }

        if (isScheduled && scheduledDate < DateTime.now()) {
            enqueueSnackbar("Scheduled date cannot be in the past.", { variant: "error" });
            return;
        }

        // We restrict the scheduled date to be within 2 weeks
        // This is to prevent message queue from storing too many messages
        if (isScheduled && scheduledDate > DateTime.now().plus({ weeks: 2 })) {
            enqueueSnackbar("Scheduled date cannot be more than 2 weeks in the future.", { variant: "error" });
            return;
        }

        // set 0 seconds and 0 milliseconds
        const scheduled_date = scheduledDate.set({ second: 0, millisecond: 0 });

        let sent_cancel_reminder = false;
        let sent_first_class_reminder = false;
        let sent_makeup_reminder = false;

        if (activeTemplates.length > 0) {
            activeTemplates.forEach((template) => {
                if (template.is_cancel_reminder_students || template.is_cancel_reminder_parents) {
                    sent_cancel_reminder = true;
                } else if (template.is_first_class_reminder_students || template.is_first_class_reminder_parents) {
                    sent_first_class_reminder = true;
                } else if (template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents) {
                    sent_makeup_reminder = true;
                }
            });
        }

        try {
            // emit to server

            if (teacher !== null) {
                socket.emit("send.MobileMessageTeacher", {
                    phone_numbers: selectedPhoneNumbers,
                    message: message,
                    teacher,
                    event_id: event.id,
                    is_scheduled: isScheduled,
                    scheduled_date: isScheduled ? scheduled_date.toISO() : null,
                    memo: memo,
                    admin_name: admin_name,
                });
            } else {
                socket.emit("send.MobileMessageStudent", {
                    phone_numbers: selectedPhoneNumbers,
                    message: message,
                    student,
                    attendance_id: attendance ? attendance.id : null,
                    sent_cancel_reminder: sent_cancel_reminder,
                    sent_first_class_reminder: sent_first_class_reminder,
                    sent_makeup_reminder: sent_makeup_reminder,
                    is_scheduled: isScheduled,
                    scheduled_date: isScheduled ? scheduled_date.toISO() : null,
                    memo: memo,
                    admin_name: admin_name,
                });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };

    function getBgcolor(template) {

        if (!activeTemplates.includes(template)) {
            return colors.primary[600];
        }

        if ((template.is_cancel_reminder_students || template.is_cancel_reminder_parents)) {
            return colors.redAccent[600];
        } else if ((template.is_first_class_reminder_students || template.is_first_class_reminder_parents)) {
            return colors.blueAccent[600];
        } else if ((template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents)) {
            return colors.greenAccent[600];
        } else if ((template.is_homework_reminder_students || template.is_homework_reminder_parents)) {
            return colors.purpleAccent[800];
        } else if ((template.is_zoom_recording_reminder_students || template.is_zoom_recording_reminder_parents)) {
            return colors.yellowAccent[600];
        } else {
            // late or others
            return colors.blueAccent[900];
        }
    }

    function notShowingUnneededTemplates(template) {
        if (teacher_course) {
            // teacher
            // so we don't need student and parents templates
            if (template.is_first_class_reminder_students || template.is_first_class_reminder_parents
                || template.is_cancel_reminder_students || template.is_cancel_reminder_parents
                || template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents
                || template.is_homework_reminder_students || template.is_homework_reminder_parents
                || template.is_zoom_recording_reminder_students || template.is_zoom_recording_reminder_parents
                || template.is_late_attendance_students || template.is_late_attendance_parents) {

                return false;
            }
        } else {
            // student
            // so we don't need teacher templates
            if (template.is_first_class_reminder_teachers || template.is_first_class_reminder_teachers
                || template.is_makeup_class_reminder_teachers || template.is_makeup_class_reminder_teachers
                || template.is_cancel_reminder_teachers || template.is_cancel_reminder_teachers) {
                
                return false;
            }
        }

        return true;
    }

    function getAreYouSureMessage() {
        let message = "";
        if (activeTemplates.length > 1) {
            message += "You have selected multiple templates. ";
        }

        message += "Are you sure you want to send the message?";

        if (isScheduled) {
            message += " (The message is scheduled for " + scheduledDate.toLocaleString(DateTime.DATETIME_FULL) + " America/New_York time.)";
        }

        return message;
    }

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.blueAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.blueAccent[900],
                    },
                }}
            >
                Send Message
            </Button>
            <Modal
                aria-labelledby="attendance-modal"
                aria-describedby="attendance-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Send Message
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mb: 2 }}>
                                <FormGroup>
                                    {student && student.phone && student.phone !== "" && (
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    onChange={(e) => handleChange(e, getCountryPhoneCode(student.country_phone_code) + student.phone)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label={"Student: " + getCountryPhoneCode(student.country_phone_code) + " " + student.phone}
                                            labelPlacement="end"
                                        />
                                    )}
                                    {student && student.parent_phone && student.parent_phone !== "" && (
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    onChange={(e) => handleChange(e, getCountryPhoneCode(student.parent_country_phone_code) + student.parent_phone)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label={"Parent 1: " + getCountryPhoneCode(student.parent_country_phone_code) + " " + student.parent_phone}
                                            labelPlacement="end"
                                        />
                                    )}
                                    {student && student.parent_phone_2 && student.parent_phone_2 !== "" && (
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    onChange={(e) => handleChange(e, getCountryPhoneCode(student.parent_country_phone_code_2) + student.parent_phone_2)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label={"Parent 2: " + getCountryPhoneCode(student.parent_country_phone_code_2) + " " + student.parent_phone_2}
                                            labelPlacement="end"
                                        />
                                    )}
                                    {teacher && teacher.phone && teacher.phone !== "" && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => handleChange(e, getCountryPhoneCode(teacher.country_phone_code) + teacher.phone)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={"Teacher: " + getCountryPhoneCode(teacher.country_phone_code) + " " + teacher.phone}
                                            labelPlacement="end"
                                        />
                                    )}
                                </FormGroup>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography variant="h7" color={colors.primary[450]}>
                                        {activeTemplates.length} Template(s) Selected
                                    </Typography>
                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary[100],
                                            textTransform: "none",
                                            textDecoration: "underline",
                                            '&:hover': {
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={() => {setHideAll(!hideAll)}}
                                    >
                                        {hideAll ? "Show All Templates" : "Hide All Templates"}
                                    </Button>
                                </Box>
                                {!hideAll && messageTemplates && messageTemplates.map((template, index) => {
                                    
                                    if (!notShowingUnneededTemplates(template)) {
                                        return;
                                    }

                                    return (
                                        <Button 
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                fontSize: 12,
                                                bgcolor: getBgcolor(template),
                                                color: colors.primary[100],
                                                textTransform: "none",
                                            }}
                                            onClick={() => {
                                                handleMessageTemplateButtonClick(template);
                                            }}
                                        >
                                            {template.name}
                                        </Button>
                                    );
                                })}
                            </Box>
                            <TextField
                                id="message"
                                label="Message"
                                multiline
                                rows={8}
                                fullWidth
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                    setMessageLength(e.target.value.length);
                                }}
                                sx={{ mt: 2 }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                    justifyContent: "flex-end",
                                    mr: 2,
                                    gap: 2,
                                    width: "100%",
                                }}
                            >
                                <Typography 
                                    variant="h7" 
                                    color={messageLength < 161 ? 'primary' : colors.redAccent[500]}
                                >
                                    ({messageLength} / 160)
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 2,
                                    mt: 4,
                                }}
                            >
                                <FormControlLabel 
                                    control={
                                        <CheckBox
                                            checked={isScheduled}
                                            onChange={(e) => setIsScheduled(e.target.checked)}
                                        />
                                    }
                                    label={"Scheduled"}
                                    labelPlacement="end"
                                />
                            </Box>
                            {isScheduled && (<>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        mt: 2,
                                        width: "100%",
                                        height: "60px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <DateTimePicker
                                            disabled={!isScheduled}
                                            timezone="America/New_York"
                                            label="Scheduled Date (America/New_York)"
                                            value={scheduledDate}
                                            onChange={(newValue) => setScheduledDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        id="admin_name"
                                        label="Admin Name"
                                        disabled
                                        type="text"
                                        value={admin_name}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                        mt: 2,
                                        width: "90%",
                                    }}
                                >
                                    <TextField
                                        id='memo'
                                        label='Memo for record purposes'
                                        type='text'
                                        multiline
                                        fullWidth
                                        rows={6}
                                        value={memo}
                                        onChange={(e) => setMemo(e.target.value)}
                                        placeholder='Memo for the scheduled message'
                                        sx={{ mt: 2 }}
                                    />
                                </Box>
                            </>)}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => setAreYouSureModalOpen(true)}
                            >
                                Send
                            </Button>
                            <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message={getAreYouSureMessage()}
                                onConfirm={handleSend}
                            />
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default SendMessageButtonModal;