import React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../adminDashboard/Sidebar';
import Topbar from '../adminDashboard/Topbar';
import { Navigate } from 'react-router-dom';
import getCookies from '../utils/getCookies';
import Axios from 'axios';
import { SocketContext, socket } from '../socket';


const keyPathValueRestrictions = {
    "/": "dashboard",
    "/admin/home": "dashboard",
    "/admin/attendance": "attendance",
    "/admin/weekly": "weekly",
    "/admin/consultationProgress": "상담 진행 상황",
    "/admin/potentialCourses": "다음 학기 예상 스케줄",
    "/admin/weekly": "weekly",
    "/admin/students": "students",
    "/admin/potentialStudents": "potential students",
    "/admin/teachers": "teachers",
    "/admin/potentialTeachers": "potential teachers",
    "/admin/courses": "courses",
    "/admin/zoom": "zoom rooms",
    "/admin/tests": "tests",
    "/admin/textbooks": "a one library",
    "/admin/addStudent": "add student",
    "/admin/addTeacher": "add teacher",
    "/admin/addCourse": "add course",
    "/admin/addSemester": "add semester",
    "/admin/addTest": "add test",
    "/admin/addZoomRoom": "add zoom room",
    "/admin/addTextbook": "add textbook",
    "/admin/hwQuestions": "add textbook qs",
    "/admin/homework": "homework",
    "/admin/questions": "questions",
    "/admin/schoolMaterials": "school materials",
    "/admin/calendar": "calendar",
};

const AdminDashboardLayout = () => {
    const currentUser = getCookies("_auth_state");

    const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const userRole = who;
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;

    const [adminRestrictions, setAdminRestrictions] = useState([]);

    // scroll state
    const [scrollPosition, setScrollPosition] = React.useState(0);

    React.useEffect(() => {
        async function getAdminRestrictions() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/admins/${userId}/restrictions`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setAdminRestrictions(response.data.restrictions);
            }).catch((error) => {
                if (error.response.status === 401) {
                    window.location.replace("/signin");
                }

                console.log(error.response.data.message);
            });
        }

        if (userRole === "admin" && userId) {
            getAdminRestrictions();
        }
    }, []);

    if (userRole !== "admin") {
        return <Navigate to="/classrooms/classes"/>;
    }

    if (adminRestrictions && adminRestrictions.length > 0) {
        const currentPath = window.location.pathname;

        // if current path is not allowed for the current user, redirect to the dashboard
        if (currentPath !== "/" 
            && currentPath !== "/admin/home" 
            && !adminRestrictions.includes(keyPathValueRestrictions[currentPath])) {
            return window.location.replace("/");
        }
    }
    
    return currentUser ? (
        <SocketContext.Provider value={socket}>
            <Sidebar adminRestrictions={adminRestrictions} />
            <main 
                className="content"
                onScroll={(e) => {
                    setScrollPosition(e.target.scrollTop);
                }}
            >
                <Topbar adminRestrictions={adminRestrictions} />
                <Outlet context={{
                    adminRestrictions: adminRestrictions,
                    scrollPosition: scrollPosition,
                }} />
            </main>
        </SocketContext.Provider>
    ) : <Navigate to="/signin"/>;
};

export default AdminDashboardLayout;