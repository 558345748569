import * as React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { generateWeekDates, checkifDateIsInThisWeek } from '../../../utils/weekDates';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { DateTime } from 'luxon';
import Loading from '../../../components/Loading';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from 'axios';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export default function ScheduleModal({ iconColor, teacher, button = true, userId }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 1520,
        height: '80%',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: "auto",
    };
    
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setDate(DateTime.now());
        setOpen(false)
    };

    const [date, setDate] = React.useState(DateTime.now());
    const [thisWeekModules, setThisWeekModules] = React.useState({});

    const weekDates = generateWeekDates(date);
    const ROWHEIGHT = 30;
    const HOURINMINUTES = 60;
    let currentTimeLineInterval = null;

    React.useEffect(() => {
        async function fetchTeacherModules() {
            setLoading(true);
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/weekly-schedule?date=${date.toISODate()}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                let thisWeekModules = response.data.thisWeekModules || [];
                let weekDates = response.data.weekDates || null;

                if (weekDates === null) {
                    weekDates = {};
                    setThisWeekModules(weekDates);
                    setLoading(false);
                    return;
                }

                for (let i = 0; i < thisWeekModules.length; i++) {
                    const module = thisWeekModules[i];
                    // const start = DateTime.fromISO(module.start).setZone('local');
                    const start = DateTime.fromISO(module.start).setZone('America/New_York')
                    if (!weekDates[start.toISODate()] || weekDates[start.toISODate()].modules === undefined) continue;

                    weekDates[start.toISODate()].modules.push(module);
                }

                Object.keys(weekDates).forEach((date) => {
                    weekDates[date].modules.sort((a, b) => {
                        const aStart = new Date(a.start);
                        const bStart = new Date(b.start);
                        return aStart - bStart;
                    });
        
                    let countMap = weekDates[date].countMap;
                    countMap = { // count of modules for each 5 minutes
                        "08:00": 0, "08:05": 0, "08:10": 0, "08:15": 0, "08:20": 0, "08:25": 0, "08:30": 0, "08:35": 0, "08:40": 0, "08:45": 0, "08:50": 0, "08:55": 0,
                        "09:00": 0, "09:05": 0, "09:10": 0, "09:15": 0, "09:20": 0, "09:25": 0, "09:30": 0, "09:35": 0, "09:40": 0, "09:45": 0, "09:50": 0, "09:55": 0,
                        "10:00": 0, "10:05": 0, "10:10": 0, "10:15": 0, "10:20": 0, "10:25": 0, "10:30": 0, "10:35": 0, "10:40": 0, "10:45": 0, "10:50": 0, "10:55": 0,
                        "11:00": 0, "11:05": 0, "11:10": 0, "11:15": 0, "11:20": 0, "11:25": 0, "11:30": 0, "11:35": 0, "11:40": 0, "11:45": 0, "11:50": 0, "11:55": 0,
                        "12:00": 0, "12:05": 0, "12:10": 0, "12:15": 0, "12:20": 0, "12:25": 0, "12:30": 0, "12:35": 0, "12:40": 0, "12:45": 0, "12:50": 0, "12:55": 0,
                        "13:00": 0, "13:05": 0, "13:10": 0, "13:15": 0, "13:20": 0, "13:25": 0, "13:30": 0, "13:35": 0, "13:40": 0, "13:45": 0, "13:50": 0, "13:55": 0,
                        "14:00": 0, "14:05": 0, "14:10": 0, "14:15": 0, "14:20": 0, "14:25": 0, "14:30": 0, "14:35": 0, "14:40": 0, "14:45": 0, "14:50": 0, "14:55": 0,
                        "15:00": 0, "15:05": 0, "15:10": 0, "15:15": 0, "15:20": 0, "15:25": 0, "15:30": 0, "15:35": 0, "15:40": 0, "15:45": 0, "15:50": 0, "15:55": 0,
                        "16:00": 0, "16:05": 0, "16:10": 0, "16:15": 0, "16:20": 0, "16:25": 0, "16:30": 0, "16:35": 0, "16:40": 0, "16:45": 0, "16:50": 0, "16:55": 0,
                        "17:00": 0, "17:05": 0, "17:10": 0, "17:15": 0, "17:20": 0, "17:25": 0, "17:30": 0, "17:35": 0, "17:40": 0, "17:45": 0, "17:50": 0, "17:55": 0,
                        "18:00": 0, "18:05": 0, "18:10": 0, "18:15": 0, "18:20": 0, "18:25": 0, "18:30": 0, "18:35": 0, "18:40": 0, "18:45": 0, "18:50": 0, "18:55": 0,
                        "19:00": 0, "19:05": 0, "19:10": 0, "19:15": 0, "19:20": 0, "19:25": 0, "19:30": 0, "19:35": 0, "19:40": 0, "19:45": 0, "19:50": 0, "19:55": 0,
                        "20:00": 0, "20:05": 0, "20:10": 0, "20:15": 0, "20:20": 0, "20:25": 0, "20:30": 0, "20:35": 0, "20:40": 0, "20:45": 0, "20:50": 0, "20:55": 0,
                        "21:00": 0, "21:05": 0, "21:10": 0, "21:15": 0, "21:20": 0, "21:25": 0, "21:30": 0, "21:35": 0, "21:40": 0, "21:45": 0, "21:50": 0, "21:55": 0,
                        "22:00": 0, "22:05": 0, "22:10": 0, "22:15": 0, "22:20": 0, "22:25": 0, "22:30": 0, "22:35": 0, "22:40": 0, "22:45": 0, "22:50": 0, "22:55": 0,
                        "23:00": 0, "23:05": 0, "23:10": 0, "23:15": 0, "23:20": 0, "23:25": 0, "23:30": 0, "23:35": 0, "23:40": 0, "23:45": 0, "23:50": 0, "23:55": 0,
                    }
                    let moduleIdMapByTime = weekDates[date].moduleIdMapByTime;
                    moduleIdMapByTime = {
                        "08:00": [], "08:05": [], "08:10": [], "08:15": [], "08:20": [], "08:25": [], "08:30": [], "08:35": [], "08:40": [], "08:45": [], "08:50": [], "08:55": [],
                        "09:00": [], "09:05": [], "09:10": [], "09:15": [], "09:20": [], "09:25": [], "09:30": [], "09:35": [], "09:40": [], "09:45": [], "09:50": [], "09:55": [],
                        "10:00": [], "10:05": [], "10:10": [], "10:15": [], "10:20": [], "10:25": [], "10:30": [], "10:35": [], "10:40": [], "10:45": [], "10:50": [], "10:55": [],
                        "11:00": [], "11:05": [], "11:10": [], "11:15": [], "11:20": [], "11:25": [], "11:30": [], "11:35": [], "11:40": [], "11:45": [], "11:50": [], "11:55": [],
                        "12:00": [], "12:05": [], "12:10": [], "12:15": [], "12:20": [], "12:25": [], "12:30": [], "12:35": [], "12:40": [], "12:45": [], "12:50": [], "12:55": [],
                        "13:00": [], "13:05": [], "13:10": [], "13:15": [], "13:20": [], "13:25": [], "13:30": [], "13:35": [], "13:40": [], "13:45": [], "13:50": [], "13:55": [],
                        "14:00": [], "14:05": [], "14:10": [], "14:15": [], "14:20": [], "14:25": [], "14:30": [], "14:35": [], "14:40": [], "14:45": [], "14:50": [], "14:55": [],
                        "15:00": [], "15:05": [], "15:10": [], "15:15": [], "15:20": [], "15:25": [], "15:30": [], "15:35": [], "15:40": [], "15:45": [], "15:50": [], "15:55": [],
                        "16:00": [], "16:05": [], "16:10": [], "16:15": [], "16:20": [], "16:25": [], "16:30": [], "16:35": [], "16:40": [], "16:45": [], "16:50": [], "16:55": [],
                        "17:00": [], "17:05": [], "17:10": [], "17:15": [], "17:20": [], "17:25": [], "17:30": [], "17:35": [], "17:40": [], "17:45": [], "17:50": [], "17:55": [],
                        "18:00": [], "18:05": [], "18:10": [], "18:15": [], "18:20": [], "18:25": [], "18:30": [], "18:35": [], "18:40": [], "18:45": [], "18:50": [], "18:55": [],
                        "19:00": [], "19:05": [], "19:10": [], "19:15": [], "19:20": [], "19:25": [], "19:30": [], "19:35": [], "19:40": [], "19:45": [], "19:50": [], "19:55": [],
                        "20:00": [], "20:05": [], "20:10": [], "20:15": [], "20:20": [], "20:25": [], "20:30": [], "20:35": [], "20:40": [], "20:45": [], "20:50": [], "20:55": [],
                        "21:00": [], "21:05": [], "21:10": [], "21:15": [], "21:20": [], "21:25": [], "21:30": [], "21:35": [], "21:40": [], "21:45": [], "21:50": [], "21:55": [],
                        "22:00": [], "22:05": [], "22:10": [], "22:15": [], "22:20": [], "22:25": [], "22:30": [], "22:35": [], "22:40": [], "22:45": [], "22:50": [], "22:55": [],
                        "23:00": [], "23:05": [], "23:10": [], "23:15": [], "23:20": [], "23:25": [], "23:30": [], "23:35": [], "23:40": [], "23:45": [], "23:50": [], "23:55": [],
                    }
                    const modules = weekDates[date].modules;
                    modules.forEach((module) => {
                        // const moduleStartDate = new Date(module.start);
                        // const moduleEndDate = new Date(module.end);
                        // const startHour = moduleStartDate.getHours();
                        // const startMinute = moduleStartDate.getMinutes();
                        // const endHour = moduleEndDate.getHours();
                        // const endMinute = moduleEndDate.getMinutes();

                        // using luxon
                        // const moduleStartDate = DateTime.fromISO(module.start).setZone('local');
                        // const moduleEndDate = DateTime.fromISO(module.end).setZone('local');
                        const moduleStartDate = DateTime.fromISO(module.start).setZone('America/New_York');
                        const moduleEndDate = DateTime.fromISO(module.end).setZone('America/New_York');

                        const startHour = moduleStartDate.hour;
                        const startMinute = moduleStartDate.minute;
                        const endHour = moduleEndDate.hour;
                        const endMinute = moduleEndDate.minute;

                        const start = startHour * HOURINMINUTES + startMinute;
                        const end = endHour * HOURINMINUTES + endMinute;
                        for (let i = start; i < end; i += 5) { // 5 minutes interval
                            const time = (i / 60).toFixed(0);
                            const minutes = i % 60;
                            const hour = time < 10 ? `0${time}` : time;
                            const minute = minutes < 10 ? `0${minutes}` : minutes;
                            const timeString = `${hour}:${minute}`;
                            countMap[timeString] = countMap[timeString] ? countMap[timeString] + 1 : 1;
                            moduleIdMapByTime[timeString]?.push(module);
                        }
                    });
                    weekDates[date].countMap = countMap;
                    weekDates[date].moduleIdMapByTime = moduleIdMapByTime;
                });
                setThisWeekModules(weekDates);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        }

        fetchTeacherModules();
    }, [date]);

    React.useEffect(() => {
        if (currentTimeLineInterval === null) {
            currentTimeLineInterval = setInterval(() => {
                const currentTimeLine = document.getElementById("currentTimeLine");
                if (currentTimeLine === null)
                    return;
                currentTimeLine.style.top = ((DateTime.now().hour - 9) * HOURINMINUTES + DateTime.now().minute) * ROWHEIGHT / 30;
            }, 600000); // 10 minutes
        }

        return () => {
            clearInterval(currentTimeLineInterval);
        }
    }, []);

    function getModuleWidth(date, module) {
        // for examples: 2022-10-10
        const dateFormatted = DateTime.fromISO(date).toISODate();

        const countMap = thisWeekModules[dateFormatted]?.countMap;
        const moduleIdMapByTime = thisWeekModules[dateFormatted]?.moduleIdMapByTime;

        // const moduleStartDate = new Date(module.start);
        // const startHour = moduleStartDate.getHours();
        // const startMinute = moduleStartDate.getMinutes();

        // using luxon
        // const moduleStartDate = DateTime.fromISO(module.start).setZone('local');
        const moduleStartDate = DateTime.fromISO(module.start).setZone('America/New_York');
        const startHour = moduleStartDate.hour;
        const startMinute = moduleStartDate.minute;

        const start = startHour * HOURINMINUTES + startMinute;
        const time = (start / 60).toFixed(0);
        const minutes = start % 60;
        const hour = time < 10 ? `0${time}` : time;
        const minute = minutes < 10 ? `0${minutes}` : minutes;
        const timeString = `${hour}:${minute}`;

        const count = countMap?.[timeString];
        const index = moduleIdMapByTime?.[timeString]?.indexOf(module);
        const width = ((count - index) / count).toFixed(2);

        return `${width * 100}%`;
    }

    function handleDateChange(date, lOrR) {
        if (lOrR === -1)
            setDate(DateTime.now());
        else if (lOrR === 0)
            setDate(date.minus({ days: 7 }));
        else if (lOrR === 1)
            setDate(date.plus({ days: 7 }));
    }

    return (
        <div>
            {button ? 
                <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>Schedule</Button>
                :
                <IconButton onClick={handleOpen}>
                    <TodayIcon color={iconColor} />
                </IconButton>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box 
                            borderBottom={1} 
                            sx={{ 
                                position: "sticky",
                                left: 0,
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "space-between",
                            }}
                        >
                            {teacher === null ? (
                                <>
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h1" 
                                    ml={1}
                                    sx={{
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: 20,
                                        },
                                    }}
                                >
                                    Your Weekly Schedule
                                </Typography>
                                <Typography id="modal-modal-title" ml={1} sx={{display: "flex", alignItems: "center", color: "red"}}>                                
                                    <AccessTimeFilledIcon
                                        sx={{
                                            '@media screen and (max-width: 1000px)': {
                                                fontSize: 12,
                                            },
                                        }}                                                                        
                                    />
                                </Typography>
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h4" 
                                    sx={{
                                        display: "flex", 
                                        alignItems: "center", 
                                        color: "red",
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: 12,
                                        },                                        
                                    }}
                                >
                                    Based on EDT
                                </Typography>
                                <Typography id="modal-modal-title" sx={{display: "flex", alignItems: "center", color: "red"}}>                                
                                    <AccessTimeFilledIcon
                                        sx={{
                                            '@media screen and (max-width: 1000px)': {
                                                fontSize: 12,
                                            },
                                        }}
                                    /> 
                                </Typography>
                                </>
                            ) : (
                                <>
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h1" 
                                    ml={1}
                                    sx={{
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: 20,
                                        },
                                    }}
                                >
                                    {teacher.last_name}, {teacher.first_name}'s Weekly Schedule
                                </Typography>
                                <Typography id="modal-modal-title"  ml={1} sx={{display: "flex", alignItems: "center", color: "red"}}>                                
                                    <AccessTimeFilledIcon
                                        sx={{
                                            '@media screen and (max-width: 1000px)': {
                                                fontSize: 12,
                                            },
                                        }}
                                    />
                                </Typography>
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h4" 
                                    sx={{
                                        display: "flex", 
                                        alignItems: "center", 
                                        color: "red",
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: 12,
                                        },
                                    }}
                                >
                                    Based on EDT
                                </Typography>
                                <Typography id="modal-modal-title" sx={{display: "flex", alignItems: "center", color: "red"}}>                                
                                    <AccessTimeFilledIcon
                                        sx={{
                                            '@media screen and (max-width: 1000px)': {
                                                fontSize: 12,
                                            },
                                        }}
                                    /> 
                                </Typography>
                                </>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 1,
                                    mr: 1,
                                }}
                            >
                                <Button 
                                    variant='contained'
                                    type='button'
                                    color='primary'
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: colors.grey[700],
                                        },
                                        '@media screen and (max-width: 1000px)': {
                                            size: 'small',
                                        },
                                    }}
                                    onClick={() => handleDateChange(date, -1)}
                                >
                                    Today
                                </Button>
                                <Button 
                                    variant='contained'
                                    type='button'
                                    color='primary'
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: colors.grey[700],
                                        },
                                        '@media screen and (max-width: 1000px)': {
                                            size: 'small',
                                        },                                        
                                    }}
                                    onClick={() => handleDateChange(date, 0)}
                                >
                                    <KeyboardArrowLeftIcon />
                                </Button>
                                <Button 
                                    variant='contained'
                                    type='button'
                                    color='primary'
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: colors.grey[700],
                                        },
                                        '@media screen and (max-width: 1000px)': {
                                            size: 'small',
                                        },
                                    }}
                                    onClick={() => handleDateChange(date, 1)}
                                >
                                    <KeyboardArrowRightIcon />
                                </Button>
                            </Box>
                        </Box>
                        {loading ? <Loading /> : 
                            <Box
                                sx={{
                                    minHeight: 660,
                                    bgcolor: 'background.paper',
                                    mt: 2,
                                    display: "flex",
                                    flexDirection: "row",
                                    // borderBottom: "1px solid grey",
                                    // width: '100%',
                                    // height: '100%',
                                    // overflowY: "scroll",
                                }}
                            >
                                {weekDates.map((date, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                pb: 1,
                                                minWidth: index === 0 ? 50 : 200,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "fixed",
                                                    display: "flex",
                                                    borderLeft: index === 1 && "1px solid lightgrey",
                                                    borderRight: "1px solid lightgrey",
                                                    borderBottom: "1px solid lightgrey",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    bgcolor: date.date?.toISODate() === DateTime.now().toISODate() ? colors.greenAccent[500] : colors.primary[300],
                                                    minHeight: ROWHEIGHT,
                                                    width: index === 0 ? 50 : 200,
                                                    zIndex: 999999,
                                                }}
                                            >
                                                {date.date === null ? 
                                                        null
                                                    :
                                                        <Typography variant="h5" color={date.date?.toISODate() === DateTime.now().toISODate() ? 'black' : colors.primary[700]}>
                                                            {date.date.toLocaleString({ weekday: "short" })}, {date.date.toLocaleString({ month: "short" })} {date.date.day}, {date.date.year}
                                                        </Typography>
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 1,
                                                    position: "relative",
                                                    marginTop: '30px',
                                                    borderRight: "1px solid lightgrey",
                                                }}
                                            >
                                                {date.hours.map((hour, index) => {
                                                    return (
                                                        <Box 
                                                            key={index} 
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center", 
                                                                alignItems: "flex-start",
                                                                bgcolor: index % 2 === 0 && colors.primary[750],
                                                                height: ROWHEIGHT,
                                                                borderBottom: date.hours.length - 1 === index && "1px solid grey",
                                                            }}>
                                                            <Typography key={index} variant="h6">
                                                                {hour}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                                {thisWeekModules[date.date?.toISODate()]?.modules.map((module, index) => {

                                                    const moduleStartDate = DateTime.fromISO(module.start).setZone('America/New_York');
                                                    const moduleEndDate = DateTime.fromISO(module.end).setZone('America/New_York');                            

                                                    const startTime = moduleStartDate.toLocaleString(DateTime.TIME_SIMPLE);
                                                    const endTime = moduleEndDate.toLocaleString(DateTime.TIME_SIMPLE);

                                                    const startHour = moduleStartDate.hour;
                                                    const startMinute = moduleStartDate.minute;
                                                    const endHour = moduleEndDate.hour;
                                                    const endMinute = moduleEndDate.minute;


                                                    const duration = (endHour - startHour) * HOURINMINUTES + (endMinute - startMinute);
                                                    const start = (startHour - 9) * HOURINMINUTES + startMinute;
                                                    const top = start * ROWHEIGHT / 15;
                                                    const height = duration * ROWHEIGHT / 15;

                                                
                                                    function lectureTimeColor(lectureTime) {
                                                        if (lectureTime === 60) {
                                                            return "#7F00FF";
                                                        }
                                                        if (lectureTime === 90) {
                                                            return "#00CC00";
                                                        }
                                                        if (lectureTime === 120) {
                                                            return "#3399FF";
                                                        }
                                                        else
                                                            return "#7F00FF";
                                                    }

                                                    function lectureMouseOverColor(lectureTime) {
                                                        if (lectureTime === 60) {
                                                            return "#6600CC";
                                                        }
                                                        if (lectureTime === 90) {
                                                            return "#66CC00";
                                                        }
                                                        if (lectureTime === 120) {
                                                            return "#0080FF";
                                                        }
                                                        else
                                                            return "#6600CC";
                                                    }

                                                    function lectureMouseLeaveColor(lectureTime) {
                                                        if (lectureTime === 60) {
                                                            return "#B266FF";
                                                        }
                                                        if (lectureTime === 90) {
                                                            return "#00CC66";
                                                        }
                                                        if (lectureTime === 120) {
                                                            return "#66B2FF";
                                                        }
                                                        else
                                                            return "#B266FF";
                                                    }

                                                    return (
                                                        <Box
                                                            key={index}
                                                            id={module.id}
                                                            sx={{
                                                                position: "absolute",
                                                                top: top,
                                                                right: 0,
                                                                width: getModuleWidth(date.date?.toISODate(), module),
                                                                height: height,
                                                                //bgcolor: module.is_canceled ? colors.redAccent[500] : colors.blueAccent[600],
                                                                bgcolor: module.is_canceled ? colors.redAccent[500] : lectureTimeColor(duration),
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "flex-start",
                                                                color: "white",
                                                                border: "1px solid white",
                                                                borderRadius: 1,
                                                                px: 0.5,
                                                                overflow: "hidden",
                                                                zIndex: index,
                                                                transition: "width 0.5s",
                                                            }}
                                                            onMouseEnter={() => {
                                                                const thisBox = document.getElementById(module.id);
                                                                if (!thisBox)
                                                                    return;
                                                                // thisBox.style.backgroundColor = module.is_canceled ? colors.redAccent[400] : colors.blueAccent[400];
                                                                thisBox.style.backgroundColor = module.is_canceled ? colors.redAccent[400] : lectureMouseOverColor(duration);
                                                                thisBox.style.zIndex = 99999;
                                                                thisBox.style.overflow = "visible";
                                                                thisBox.style.width = "100%";
                                                            }}
                                                            onMouseLeave={() => {
                                                                const thisBox = document.getElementById(module.id);
                                                                if (!thisBox)
                                                                    return;
                                                                // thisBox.style.backgroundColor = module.is_canceled ? colors.redAccent[500] : colors.blueAccent[600];
                                                                thisBox.style.backgroundColor = module.is_canceled ? colors.redAccent[500] : lectureMouseLeaveColor(duration);
                                                                thisBox.style.zIndex = index;
                                                                thisBox.style.overflow = "hidden";
                                                                thisBox.style.width = getModuleWidth(date.date?.toISODate(), module);
                                                            }}
                                                        >
                                                            <Typography 
                                                                variant='subtitle2' 
                                                                fontSize={12} 
                                                                sx={{
                                                                    width: "100%",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    userSelect: "none",
                                                                    'msUserSelect': 'none',
                                                                    'WebkitUserSelect': 'none',
                                                                    'MozUserSelect': 'none',
                                                                    'KhtmlUserSelect': 'none',
                                                                    'OUserSelect': 'none',
                                                                }}
                                                            >
                                                                ({module.course?.code}) {module.course?.course_title}
                                                            </Typography>
                                                            <Typography 
                                                                variant='subtitle2' 
                                                                fontSize={10}
                                                                sx={{
                                                                    userSelect: "none",
                                                                    'msUserSelect': 'none',
                                                                    'WebkitUserSelect': 'none',
                                                                    'MozUserSelect': 'none',
                                                                    'KhtmlUserSelect': 'none',
                                                                    'OUserSelect': 'none',
                                                                }}
                                                            >
                                                                {startTime} - {endTime}
                                                            </Typography>
                                                            {module.is_canceled &&
                                                                <Typography
                                                                    variant='subtitle2'
                                                                    fontSize={10}
                                                                    sx={{
                                                                        fontWeight: "bold",
                                                                        userSelect: "none",
                                                                        'msUserSelect': 'none',
                                                                        'WebkitUserSelect': 'none',
                                                                        'MozUserSelect': 'none',
                                                                        'KhtmlUserSelect': 'none',
                                                                        'OUserSelect': 'none',
                                                                    }}
                                                                >
                                                                    Canceled
                                                                </Typography>
                                                            }
                                                        </Box>
                                                    );
                                                })}
                                                {/* a line for current time */}
                                                {checkifDateIsInThisWeek(date.date) &&
                                                    <Box
                                                        id="currentTimeLine"
                                                        sx={{
                                                            position: "absolute",
                                                            top: ((((DateTime.now().hour - 9) * 2)) * HOURINMINUTES + (DateTime.now().minute * 2)) * ROWHEIGHT / 30,
                                                            left: 0,
                                                            width: "100%",
                                                            minHeight: "1px",
                                                            maxHeight: "1px",
                                                            bgcolor: "red",
                                                            zIndex: 99998,
                                                        }}
                                                    ></Box>
                                                }
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        }
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};