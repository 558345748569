import React from 'react'
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AreYouSureModal from '../../../components/AreYouSureModal';

const ModuleScheduleUpdateButton = ({ 
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [lectureDate, setLectureDate] = React.useState(dayjs(event.start));
    const [fromValue, setFromValue] = React.useState(dayjs(event.start));
    const [toValue, setToValue] = React.useState(dayjs(event.end));

    React.useEffect(() => {
        if (event) {
            setLectureDate(dayjs(event.start));
            setFromValue(dayjs(event.start));
            setToValue(dayjs(event.end));
        }
    }, [event]);

    const handleSave = async () => {

        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        if (lectureDate.isSame(dayjs(event.start))
            && fromValue.isSame(dayjs(event.start))
            && toValue.isSame(dayjs(event.end))) {
            handleClose();
            return;
        }

        if (fromValue.hour() > toValue.hour() 
            || (fromValue.hour() === toValue.hour() && fromValue.minute() > toValue.minute())
            || (fromValue.hour() === toValue.hour() && fromValue.minute() === toValue.minute())) {
            enqueueSnackbar("Error: \"From time\" cannot be greater than or equal to \"To time\".", { variant: "error" });
            return;
        }

        const start_hour = fromValue.hour() < 10 ? "0" + fromValue.hour() : fromValue.hour();
        const start_minute = fromValue.minute() < 10 ? "0" + fromValue.minute() : fromValue.minute();
        const end_hour = toValue.hour() < 10 ? "0" + toValue.hour() : toValue.hour();
        const end_minute = toValue.minute() < 10 ? "0" + toValue.minute() : toValue.minute();

        const new_start = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + start_hour + ":" + start_minute + ":00");
        const new_end = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + end_hour + ":" + end_minute + ":00");

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("put.EventSchedule", {
                    admin_uuid: userId,
                    zoom_id: zoom_id,
                    date: date,
                    _time: _time,
                    event_idx: event_idx,
                    event: event,
                    new_start: new_start,
                    new_end: new_end,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.blueAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.blueAccent[700],
                    },
                }}
            >
                Update Module Schedule
            </Button>
            <Modal
                aria-labelledby="one-drive-setup-modal"
                aria-describedby="one-drive-setup-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Update Module Schedule
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                    minWidth: "100%",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Class Date"
                                        value={lectureDate}
                                        onChange={(newValue) => setLectureDate(newValue)}
                                    />
                                    <TimePicker
                                        label="From"
                                        value={fromValue}
                                        onChange={(newValue) => setFromValue(newValue)}
                                        format="HH:mm"
                                    />
                                    <TimePicker
                                        label="To"
                                        value={toValue}
                                        onChange={(newValue) => setToValue(newValue)}
                                        format="HH:mm"
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => setAreYouSureModalOpen(true)}
                            >
                                Save
                            </Button>
                            <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            />
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleClose()}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default ModuleScheduleUpdateButton