import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import Alert from '../../../components/alert/Alert';
import { tokens } from "../../../theme";
import { useTheme, Autocomplete, ListItemText } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { countryPhoneCodes } from '../../weekly/studentButtonMenu/countryPhoneCode';

export default function ModifyTab({ 
    teacher, 
    setTeacherUpdated,
    socket = null,
    socketConnected = false,
    from_weekly = false,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle = {
        '& > :not(style)': { m: 1, width: '26ch' }
    };

    const gridItemLongStyle = {
        '& > :not(style)': { m: 1, width: '81ch' }
    };

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for dialog (delete teacher)
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // for teacher type
    const [isPotentialTeacher, setIsPotentialTeacher] = React.useState(teacher.email ? false : true);
    const handleTeacherTypeChange = (event) => {
        setIsPotentialTeacher(event.target.value);
    };

    // for subject availability
    const subjectCategories = [
        "Biology",
        "Chemistry",
        "Physics",
        "Computer Science",
        "Economics",
        "English",
        "History",
        "Math",
        "Physics",
        "Research",
        "Writing Competition",
    ];
    const [selectedSubjects, setSelectedSubjects] = React.useState(teacher.subject ? teacher.subject.split('|') : []);
    const handleSubjectAvailabilityChange = (event) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        setSelectedSubjects(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    // for country phone code
    const [countryPhoneCode, setCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.code + " " + option.name === teacher.country_phone_code));

    // for addMemo checkbox
    const [addMemo, setAddMemo] = React.useState(teacher.memo ? true : false);
    const handleAddMemoChange = (event) => {
        setAddMemo(event.target.checked);
    };
    const [memoContent, setMemoContent] = React.useState(teacher.memo ? teacher.memo : "");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let first_name = data.get('first_name') && data.get('first_name') !== "" ? data.get('first_name').trim() : null;
        let last_name = data.get('last_name') && data.get('last_name') !== "" ? data.get('last_name').trim() : null;
        let phone = data.get('phone') && data.get('phone') !== "" ? data.get('phone').trim() : null;
        let email = data.get('email') && data.get('email') !== "" ? data.get('email').toLowerCase().trim() : null;

        if (first_name === null || last_name === null || phone === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("First name, last name, and phone are required.");
            return;
        }

        if (!isPotentialTeacher && email === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Email is required if the teacher is not a potential teacher.");
            return;
        }

        if (phone !== null && phone !== "" && countryPhoneCode === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Invalid country phone code.");
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher.uuid}`, {
            first_name: first_name,
            last_name: last_name,
            school: data.get('school'),
            major: data.get('major'),
            degree: data.get('degree'),
            subjectCategories: selectedSubjects.join('|'),
            email: email,
            phone: phone,
            street: data.get('street'),
            city: data.get('city'),
            state: data.get('state'),
            zip: data.get('zip'),
            country: data.get('country'),
            zelle: data.get('zelle'),
            venmo: data.get('venmo'),
            personal_email: data.get('personal_email') && data.get('personal_email') !== "" ? data.get('personal_email').toLowerCase().trim() : null,
            interview_eval: isPotentialTeacher && data.get('interview_eval') && data.get('interview_eval') !== "" ? data.get('interview_eval') : null,
            memo: addMemo ? memoContent : null,
            country_phone_code: countryPhoneCode ? countryPhoneCode.code + " " + countryPhoneCode.name : "+1 United States",
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("put.TeacherUpdate", {
                    teacher_uuid: teacher.uuid,
                    message: " updated a teacher's information (" + first_name + " " + last_name + ").",
                });
            } else {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                setTeacherUpdated(true);
            }
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    const handleDelete = async () => {
        handleDialogClose();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("delete.TeacherDelete", {
                    teacher_uuid: teacher.uuid,
                    message: " deleted a teacher (" + teacher.first_name + " " + teacher.last_name + ").",
                });
            } else {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    React.useEffect(() => {
        setIsPotentialTeacher(teacher.email ? false : true);

        const first_name = document.getElementById('first_name');
        const last_name = document.getElementById('last_name');
        const school = document.getElementById('school');
        const major = document.getElementById('major');
        const degree = document.getElementById('degree');
        const subject = document.getElementById('subject');
        const interview_eval = document.getElementById('interview_eval');
        const email = document.getElementById('email');
        const personal_email = document.getElementById('personal_email');
        const phone = document.getElementById('phone');
        const street = document.getElementById('street');
        const city = document.getElementById('city');
        const state = document.getElementById('state');
        const zip = document.getElementById('zip');
        const country = document.getElementById('country');
        const zelle = document.getElementById('zelle');
        const venmo = document.getElementById('venmo');

        if (teacher && from_weekly) {
            first_name.value = teacher.first_name ? teacher.first_name : '';
            last_name.value = teacher.last_name ? teacher.last_name : '';
            school.value = teacher.school ? teacher.school : '';
            major.value = teacher.major ? teacher.major : '';
            degree.value = teacher.degree ? teacher.degree : '';
            subject.value = teacher.subject ? teacher.subject.split('|') : [];
            if (isPotentialTeacher) {
                interview_eval.value = teacher.interview_eval ? teacher.interview_eval : null;
            }
            email.value = teacher.email ? teacher.email : '';
            personal_email.value = teacher.personal_email ? teacher.personal_email : '';
            phone.value = teacher.phone ? teacher.phone : '';
            street.value = teacher.address ? teacher.address.street : null;
            city.value = teacher.address ? teacher.address.city : null;
            state.value = teacher.address ? teacher.address.state : null;
            zip.value = teacher.address ? teacher.address.zip : null;
            country.value = teacher.address ? teacher.address.country : null;
            zelle.value = teacher.zelle ? teacher.zelle : '';
            venmo.value = teacher.venmo ? teacher.venmo : '';
            setMemoContent(teacher.memo ? teacher.memo : '');
        }

    }, [teacher]);

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="first_name"
                            name="first_name"
                            label="First name"
                            variant="filled"
                            defaultValue={teacher.first_name}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="last_name"
                            name="last_name"
                            label="Last name"
                            variant="filled"
                            defaultValue={teacher.last_name}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="school"
                            name="school"
                            label="School"
                            variant="filled"
                            defaultValue={teacher.school}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="major"
                            name="major"
                            label="Major"
                            variant="filled"
                            defaultValue={teacher.major}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="degree"
                            name="degree"
                            label="Degree"
                            variant="filled"
                            defaultValue={teacher.degree}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <FormControl variant="filled">
                            <InputLabel id="subject">Subject</InputLabel>
                            <Select
                                labelId="subject"
                                id="subject"
                                multiple
                                value={selectedSubjects}
                                onChange={handleSubjectAvailabilityChange}                                
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {subjectCategories.map((subject, index) => (
                                    <MenuItem key={index} value={subject}>
                                        <Checkbox checked={selectedSubjects.indexOf(subject) > -1} />
                                        <ListItemText primary={subject} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                            <InputLabel id="demo-simple-select-filled-label">Teacher Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={isPotentialTeacher}
                                onChange={handleTeacherTypeChange}
                            >
                                <MenuItem value={false}>Teacher</MenuItem>
                                <MenuItem value={true}>Potential Teacher</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        {isPotentialTeacher ? (
                            <TextField
                                type="number"
                                inputProps={{ min: "1", max: "3" }}
                                id="interview_eval"
                                name="interview_eval"
                                label="Interview Evaluation (1: X, 2: △, 3: O)"
                                variant="filled"
                                defaultValue={teacher.interview_eval}
                            />
                        ) : (
                            <Box sx={{ gridColumn: "span 1", height: "50px" }}>

                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            disabled={isPotentialTeacher}
                            id="email"
                            name="email"
                            label="Email"
                            variant="filled"
                            defaultValue={teacher.email}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="personal_email"
                            name="personal_email"
                            label="Personal Email"
                            variant="filled"
                            defaultValue={teacher.personal_email}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            id="country-phone-code"
                            value={countryPhoneCode}
                            options={countryPhoneCodes}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            sx={{ width: "50%" }}
                            renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                            onChange={(e, value) => {
                                setCountryPhoneCode(value);
                            }}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="phone"
                            name="phone"
                            label="Phone"
                            variant="filled"
                            defaultValue={teacher.phone}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <FormControlLabel
                            control={<Checkbox checked={addMemo} onChange={handleAddMemoChange} />}
                            label="Want to add a memo?"
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Grid>
                </Grid>
                {addMemo &&
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={gridItemLongStyle}>
                            <TextField
                                id="memo"
                                label="Memo (optional)"
                                variant="filled"
                                name='memo'
                                value={memoContent}
                                onChange={(e) => setMemoContent(e.target.value)}
                                fullWidth={true}
                                multiline={true}
                                rows={8}
                            />
                        </Grid>
                    </Grid>
                }

                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="street"
                            name="street"
                            label="Street"
                            variant="filled"
                            defaultValue={teacher.address.street}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="city"
                            name="city"
                            label="City"
                            variant="filled"
                            defaultValue={teacher.address.city}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="state"
                            name="state"
                            label="State"
                            variant="filled"
                            defaultValue={teacher.address.state}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="zip"
                            name="zip"
                            label="Zip"
                            variant="filled"
                            defaultValue={teacher.address.zip}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="country"
                            name="country"
                            label="Country"
                            variant="filled"
                            defaultValue={teacher.address.country}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="zelle"
                            name="zelle"
                            label="Zelle"
                            variant="filled"
                            defaultValue={teacher.zelle}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="venmo"
                            name="venmo"
                            label="Venmo"
                            variant="filled"
                            defaultValue={teacher.venmo}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        "backgroundColor": colors.primary[300],
                        "color": "white",
                        "float": "center",
                        "marginTop": "10px",
                        "width": "100%",
                    }}
                >
                    Update
                </Button>
            </Box>
            <Button
                variant="contained"
                style={{
                    "backgroundColor": colors.redAccent[500],
                    "color": "white",
                    "float": "center",
                    "marginTop": "15px",
                    "width": "100%",
                }}
                onClick={handleClickDialogOpen}
            >
                Delete this teacher
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this teacher's data?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleDelete} color="error" autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>

            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='top'
                horizontal='right'
            />
        </>
    )
}