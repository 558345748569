import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import AreYouSureModal from '../../../components/AreYouSureModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';


function TemplateAccodion({
    admin_name,
    _mobileMessageTemplate,
    enqueueSnackbar,
    socket,
    socketConnected,
    is_create,
}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [mobileMessageTemplate, setMobileMessageTemplate] = React.useState(
        is_create ? {
            id: null,
            name: "",
            template: "",
        } : {
            id: _mobileMessageTemplate.id,
            name: _mobileMessageTemplate.name,
            template: _mobileMessageTemplate.template,
        }
    );
    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [areYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] = React.useState(false);

    const [isCancelReminderStudents, setIsCancelReminderStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_cancel_reminder_students);
    const [isCancelReminderParents, setIsCancelReminderParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_cancel_reminder_parents);
    const [isCancelReminderTeachers, setIsCancelReminderTeachers] = React.useState(is_create ? false : _mobileMessageTemplate?.is_cancel_reminder_teachers);
    const [isFirstClassReminderStudents, setIsFirstClassReminderStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_first_class_reminder_students);
    const [isFirstClassReminderParents, setIsFirstClassReminderParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_first_class_reminder_parents);
    const [isFirstClassReminderTeachers, setIsFirstClassReminderTeachers] = React.useState(is_create ? false : _mobileMessageTemplate?.is_first_class_reminder_teachers);
    const [isMakeupClassReminderStudents, setIsMakeupClassReminderStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_makeup_class_reminder_students);
    const [isMakeupClassReminderParents, setIsMakeupClassReminderParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_makeup_class_reminder_parents);
    const [isMakeupClassReminderTeachers, setIsMakeupClassReminderTeachers] = React.useState(is_create ? false : _mobileMessageTemplate?.is_makeup_class_reminder_teachers);
    const [isHomeworkReminderStudents, setIsHomeworkReminderStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_homework_reminder_students);
    const [isHomeworkReminderParents, setIsHomeworkReminderParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_homework_reminder_parents);
    const [isZoomRecordingReminderStudents, setIsZoomRecordingReminderStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_zoom_recording_reminder_students);
    const [isZoomRecordingReminderParents, setIsZoomRecordingReminderParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_zoom_recording_reminder_parents);
    const [isLateAttendanceStudents, setIsLateAttendanceStudents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_late_attendance_students);
    const [isLateAttendanceParents, setIsLateAttendanceParents] = React.useState(is_create ? false : _mobileMessageTemplate?.is_late_attendance_parents);

    React.useEffect(() => {
        if (_mobileMessageTemplate) {
            setMobileMessageTemplate({
                id: _mobileMessageTemplate.id,
                name: _mobileMessageTemplate.name,
                template: _mobileMessageTemplate.template,
            });

            setIsCancelReminderStudents(_mobileMessageTemplate.is_cancel_reminder_students);
            setIsCancelReminderParents(_mobileMessageTemplate.is_cancel_reminder_parents);
            setIsCancelReminderTeachers(_mobileMessageTemplate.is_cancel_reminder_teachers);
            setIsFirstClassReminderStudents(_mobileMessageTemplate.is_first_class_reminder_students);
            setIsFirstClassReminderParents(_mobileMessageTemplate.is_first_class_reminder_parents);
            setIsFirstClassReminderTeachers(_mobileMessageTemplate.is_first_class_reminder_teachers);
            setIsMakeupClassReminderStudents(_mobileMessageTemplate.is_makeup_class_reminder_students);
            setIsMakeupClassReminderParents(_mobileMessageTemplate.is_makeup_class_reminder_parents);
            setIsMakeupClassReminderTeachers(_mobileMessageTemplate.is_makeup_class_reminder_teachers);
            setIsHomeworkReminderStudents(_mobileMessageTemplate.is_homework_reminder_students);
            setIsHomeworkReminderParents(_mobileMessageTemplate.is_homework_reminder_parents);
            setIsZoomRecordingReminderStudents(_mobileMessageTemplate.is_zoom_recording_reminder_students);
            setIsZoomRecordingReminderParents(_mobileMessageTemplate.is_zoom_recording_reminder_parents);
            setIsLateAttendanceStudents(_mobileMessageTemplate.is_late_attendance_students);
            setIsLateAttendanceParents(_mobileMessageTemplate.is_late_attendance_parents);
        }
    }, [_mobileMessageTemplate]);

    const handleDelete = () => {
        if (!mobileMessageTemplate || !mobileMessageTemplate.id) {
            enqueueSnackbar("Something went wrong. Please refresh the page.", { variant: "error" });
            return;
        }

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("delete.MobileMessageTemplate", {
                    admin_name: admin_name,
                    id: mobileMessageTemplate.id,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot delete the template. Please try again.", { variant: "error" });
            return;
        }
    };

    const handleSubmit = () => {
        if (!mobileMessageTemplate) {
            enqueueSnackbar("Select a template to save.", { variant: "warning" });
            return;
        }

        if (!mobileMessageTemplate.name) {
            enqueueSnackbar("Name is required.", { variant: "warning" });
            return;
        }

        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("put.MobileMessageTemplate", {
                    admin_name: admin_name,
                    id: mobileMessageTemplate.id,
                    name: mobileMessageTemplate.name,
                    template: mobileMessageTemplate.template ? mobileMessageTemplate.template : "",
                    is_cancel_reminder_students: isCancelReminderStudents,
                    is_cancel_reminder_parents: isCancelReminderParents,
                    is_cancel_reminder_teachers: isCancelReminderTeachers,
                    is_first_class_reminder_students: isFirstClassReminderStudents,
                    is_first_class_reminder_parents: isFirstClassReminderParents,
                    is_first_class_reminder_teachers: isFirstClassReminderTeachers,
                    is_makeup_class_reminder_students: isMakeupClassReminderStudents,
                    is_makeup_class_reminder_parents: isMakeupClassReminderParents,
                    is_makeup_class_reminder_teachers: isMakeupClassReminderTeachers,
                    is_homework_reminder_students: isHomeworkReminderStudents,
                    is_homework_reminder_parents: isHomeworkReminderParents,
                    is_zoom_recording_reminder_students: isZoomRecordingReminderStudents,
                    is_zoom_recording_reminder_parents: isZoomRecordingReminderParents,
                    is_late_attendance_students: isLateAttendanceStudents,
                    is_late_attendance_parents: isLateAttendanceParents,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }
    };

    function setFalseForAllExceptArg(arg) {
        /**
         * args:
         * - isCancelReminderStudents
         * - isFirstClassReminderStudents
         * - isMakeupClassReminderStudents
         * - isHomeworkReminderStudents
         * - isZoomRecordingReminderStudents
         * - isLateAttendanceStudents
         * - isCancelReminderParents
         * - isFirstClassReminderParents
         * - isMakeupClassReminderParents
         * - isHomeworkReminderParents
         * - isZoomRecordingReminderParents
         * - isLateAttendanceParents
         * - isCancelReminderTeachers
         * - isFirstClassReminderTeachers
         * - isMakeupClassReminderTeachers
         */

        if (arg !== "isCancelReminderStudents")
            setIsCancelReminderStudents(false);
        if (arg !== "isFirstClassReminderStudents")
            setIsFirstClassReminderStudents(false);
        if (arg !== "isMakeupClassReminderStudents")
            setIsMakeupClassReminderStudents(false);
        if (arg !== "isHomeworkReminderStudents")
            setIsHomeworkReminderStudents(false);
        if (arg !== "isZoomRecordingReminderStudents")
            setIsZoomRecordingReminderStudents(false);
        if (arg !== "isLateAttendanceStudents")
            setIsLateAttendanceStudents(false);
        if (arg !== "isCancelReminderParents")
            setIsCancelReminderParents(false);
        if (arg !== "isFirstClassReminderParents")
            setIsFirstClassReminderParents(false);
        if (arg !== "isMakeupClassReminderParents")
            setIsMakeupClassReminderParents(false);
        if (arg !== "isHomeworkReminderParents")
            setIsHomeworkReminderParents(false);
        if (arg !== "isZoomRecordingReminderParents")
            setIsZoomRecordingReminderParents(false);
        if (arg !== "isLateAttendanceParents")
            setIsLateAttendanceParents(false);
        if (arg !== "isCancelReminderTeachers")
            setIsCancelReminderTeachers(false);
        if (arg !== "isFirstClassReminderTeachers")
            setIsFirstClassReminderTeachers(false);
        if (arg !== "isMakeupClassReminderTeachers")
            setIsMakeupClassReminderTeachers(false);

    }

    return (
        <div style={{
            width: "100%",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>{is_create ? "+ Create a new template" : mobileMessageTemplate.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            mr: "1rem",
                            width: "100%",
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            value={mobileMessageTemplate.name}
                            onChange={(event) => {
                                setMobileMessageTemplate({
                                    ...mobileMessageTemplate,
                                    name: event.target.value,
                                });
                            }}
                            sx={{
                                width: "200px",
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Template"
                            variant="outlined"
                            multiline
                            rows={6}
                            fullWidth
                            value={mobileMessageTemplate.template}
                            onChange={(event) => {
                                setMobileMessageTemplate({
                                    ...mobileMessageTemplate,
                                    template: event.target.value,
                                });
                            }}
                        />
                        <FormLabel component="legend">
                            To Students
                        </FormLabel>
                        <FormGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isCancelReminderStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsCancelReminderStudents(e.target.checked);
                                            setFalseForAllExceptArg("isCancelReminderStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Cancel Reminder" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isFirstClassReminderStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsFirstClassReminderStudents(e.target.checked);
                                            setFalseForAllExceptArg("isFirstClassReminderStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="First Class Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isMakeupClassReminderStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsMakeupClassReminderStudents(e.target.checked);
                                            setFalseForAllExceptArg("isMakeupClassReminderStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Makeup Class Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isHomeworkReminderStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsHomeworkReminderStudents(e.target.checked);
                                            setFalseForAllExceptArg("isHomeworkReminderStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Homework Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isZoomRecordingReminderStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsZoomRecordingReminderStudents(e.target.checked);
                                            setFalseForAllExceptArg("isZoomRecordingReminderStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Zoom Recording Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isLateAttendanceStudents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsLateAttendanceStudents(e.target.checked);
                                            setFalseForAllExceptArg("isLateAttendanceStudents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Late Attendance"
                            />
                        </FormGroup>
                        
                        <FormLabel component="legend">
                            To Parents
                        </FormLabel>
                        <FormGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isCancelReminderParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsCancelReminderParents(e.target.checked);
                                            setFalseForAllExceptArg("isCancelReminderParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Cancel Reminder" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isFirstClassReminderParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsFirstClassReminderParents(e.target.checked);
                                            setFalseForAllExceptArg("isFirstClassReminderParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="First Class Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isMakeupClassReminderParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsMakeupClassReminderParents(e.target.checked);
                                            setFalseForAllExceptArg("isMakeupClassReminderParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Makeup Class Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isHomeworkReminderParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsHomeworkReminderParents(e.target.checked);
                                            setFalseForAllExceptArg("isHomeworkReminderParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Homework Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isZoomRecordingReminderParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsZoomRecordingReminderParents(e.target.checked);
                                            setFalseForAllExceptArg("isZoomRecordingReminderParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Zoom Recording Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isLateAttendanceParents}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsLateAttendanceParents(e.target.checked);
                                            setFalseForAllExceptArg("isLateAttendanceParents");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Late Attendance"
                            />
                        </FormGroup>

                        <FormLabel component="legend">
                            To Teachers
                        </FormLabel>
                        <FormGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isCancelReminderTeachers}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsCancelReminderTeachers(e.target.checked);
                                            setFalseForAllExceptArg("isCancelReminderTeachers");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Cancel Reminder" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isFirstClassReminderTeachers}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsFirstClassReminderTeachers(e.target.checked);
                                            setFalseForAllExceptArg("isFirstClassReminderTeachers");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="First Class Reminder"
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isMakeupClassReminderTeachers}
                                        onChange={(e) => {
                                            e.stopPropagation();

                                            // make sure only one is checked
                                            setIsMakeupClassReminderTeachers(e.target.checked);
                                            setFalseForAllExceptArg("isMakeupClassReminderTeachers");
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Makeup Class Reminder"
                            />
                        </FormGroup>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                width: "100%",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    marginTop: "1rem",
                                    width: "140px",
                                }}
                                onClick={() => {
                                    setAreYouSureModalOpen(true);
                                }}
                            >
                                {is_create ? "Create" : "Save"}
                            </Button>
                            <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message={is_create ? "Are you sure you want to create this template?" : "Are you sure you want to save the changes?"}
                                onConfirm={handleSubmit}
                            />
                            <Button
                                variant="contained"
                                color="error"
                                style={{
                                    marginTop: "1rem",
                                    width: "140px",
                                }}
                                onClick={() => {
                                    setAreYouSureDeleteModalOpen(true);
                                }}
                            >
                                Delete
                            </Button>
                            <AreYouSureModal
                                colors={colors}
                                open={areYouSureDeleteModalOpen}
                                setOpen={setAreYouSureDeleteModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to delete this template?"
                                onConfirm={handleDelete}
                            />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default TemplateAccodion