import React from 'react';
import { tokens } from "../../theme";
import { Box, Typography, useTheme, Menu, MenuItem, Autocomplete, TextField, ListItemText } from '@mui/material';
import { DateTime } from 'luxon';
import { generateWeekDates, generateWeekDatesForTeacherScheduler, generateWeekDatesForAdmin } from '../../utils/weekDates';
import Button from '@mui/material/Button';
import Loading from '../../components/Loading';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from 'axios';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Alert from '../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import TeacherChip from './TeacherChip';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


const hours = [
    {label: "8:00 AM", value: "08:00"},
    {label: "9:00 AM", value: "09:00"},
    {label: "10:00 AM", value: "10:00"},
    {label: "11:00 AM", value: "11:00"},
    {label: "12:00 PM", value: "12:00"},
    {label: "1:00 PM", value: "13:00"},
    {label: "2:00 PM", value: "14:00"},
    {label: "3:00 PM", value: "15:00"},
    {label: "4:00 PM", value: "16:00"},
    {label: "5:00 PM", value: "17:00"},
    {label: "6:00 PM", value: "18:00"},
    {label: "7:00 PM", value: "19:00"},
    {label: "8:00 PM", value: "20:00"},
    {label: "9:00 PM", value: "21:00"},
    {label: "10:00 PM", value: "22:00"},
    {label: "11:00 PM", value: "23:00"},
];


const TeacherScheduleCheck = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isDarkMode = theme.palette.mode === 'dark';

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }; 

    const durations = [
        { label: '30 minutes', value: 0.5 },
        { label: '1 hour', value: 1 },
        { label: '2 hours', value: 2 },
    ];

    // for subject availability
    const subjectCategories = [
        "Biology",
        "Chemistry",
        "Physics",
        "Computer Science",
        "Economics",
        "English",
        "History",
        "Math",
        "Physics",
        "Research",
        "Writing Competition",
    ];

    const [targetSubjects, setTargetSubjects] = React.useState([]);
    
    const handleSubjectChange = (event) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        setTargetSubjects(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const [open, setOpen] = React.useState(false);

    const handleReset = () => {
        setDuration(0.5);
        setStartTime("08:00");
        setEndTime("00:00");
        // setDate(one_week_later);
        // setDeadlineDate(one_week_ago);
        setWeekDates(generateWeekDatesForTeacherScheduler(date, 0.5));
    };
    
    const timeModalOpen = () => {
        setOpen(true);
    };

    const timeModalClose = () => {
        setOpen(false);
    };

    const today = DateTime.now();
    const one_week_ago = today.minus({ week: 1 });
    const one_week_later = today.plus({ week: 1 });

    const [date, setDate] = React.useState(one_week_later);
    const [deadlineDate, setDeadlineDate] = React.useState(one_week_ago);

    const [duration, setDuration] = React.useState(0.5);
    const weekForDeadline = generateWeekDates(deadlineDate);
    const [weekDates, setWeekDates] = React.useState(generateWeekDatesForTeacherScheduler(date, duration));

    // const deadline_date = weekForDeadline[3].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('DDDD');

    // Deadline date for the current week    
    const deadline_date = weekForDeadline && weekForDeadline.length > 0 
        && weekForDeadline[weekForDeadline.length - 1].date.toJSDate() < new Date('2024-10-07T23:59:59.000Z') 
        && weekForDeadline[1].date.toJSDate() > new Date('2024-09-29T00:00:00.000Z') 
        ? weekForDeadline[5].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD') 
        : weekForDeadline[3].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD');
    
    const [loading, setLoading] = React.useState(false);

    const [selectedSlots, setSelectedSlots] = React.useState([]);
    const [possibleSlots, setPossibleSlots] = React.useState([]);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

   // Adjust Popover for each specific cell
   // Popover for each cell where possible teachers are available
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState({});

    const handlePopoverOpen = (event, key) => {
        setPopoverAnchorEl(prevState => ({
            ...prevState,
            [key]: event.currentTarget
        }));
    };

    const handlePopoverClose = (key) => {
        setPopoverAnchorEl(prevState => ({
            ...prevState,
            [key]: null
        }));
    };

    const popoverOpen = (key) => Boolean(popoverAnchorEl[key]);
    const popoverId = (key) => popoverOpen(key) ? `popover-${key}` : undefined;

    const [startTime, setStartTime] = React.useState("08:00");
    const [endTime, setEndTime] = React.useState("00:00");

    // Helper function to find the matching time object in hours
    const findHourObject = (timeValue) => hours.find(hour => hour.value === timeValue);

    const handleTimeRangeChange = () => {
        // setWeekDates(generateWeekDatesForTeacherScheduler(date, duration, start, end));

        const start = DateTime.fromISO(`${date.toISODate()}T${startTime}`);
        const end = DateTime.fromISO(`${date.toISODate()}T${endTime}`);
        
        if (start >= end) {
            setErrorAlertMessage("Start time must be before end time.");
            setErrorAlertOpen(true);
            return;
        };

        setWeekDates(generateWeekDatesForAdmin(date, duration, startTime, endTime));
    };
    
    // const [ROWHEIGHT, setROWHEIGHT] = React.useState(30);
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Fetch teacher's availability
    React.useEffect(() => {
        const fetchTeacherAvailability = async () => {
            if (weekDates.length === 0 || !weekDates[1]?.date || !weekDates[7]?.date) {
                console.error("WeekDates array is not properly populated.");
                return;
            };
            
            setLoading(true);
            try {
                const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/availability/all`, {
                    params: {
                        week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                        week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
                        subjects: targetSubjects,
                    },
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                });
                const availability = response.data;
                let duration_count = 0;

                if (availability && availability.length > 0) {
                    const slotMap = {};
                    const possibleSlotMap = {};

                    // need to see if startTime's hour is even or odd
                    const startHour = parseInt(startTime.split(":")[0]);
                    const startHourIsEven = startHour % 2 === 0;

                    for (let i = 0; i < availability.length; i++) {
                        const slot = availability[i];

                        // 1 condition: startTime >= slot.start_time && endTime <= slot.end_time
                        // slot
                        const hour = slot.date.split("T")[1].split(":")[0];
                        const minute = slot.date.split("T")[1].split(":")[1];

                        if (duration === 0.5) {
                            duration_count = 0;
                        } else if (duration === 1 && minute === "00") {
                            duration_count = 0;
                        } else if (duration === 2 && minute === "00" && ((startHourIsEven && hour % 2 === 0) || (!startHourIsEven && hour % 2 === 1))) {
                            duration_count = 0;
                        };

                        let date = DateTime.fromISO(slot.date);
                        let dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === date.toISODate());
                        let slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === date.toFormat('h:mm a'));
                    
                        // get first slot key (very important!)
                        duration_count++;
                        
                        if (duration === 0.5 || (duration === 1 && duration_count === 2) || (duration === 2 && duration_count === 4)) {

                            if (duration === 1) {
                                date = DateTime.fromISO(availability[i - 1].date);
                                dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === date.toISODate());
                                slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === date.toFormat('h:mm a'));
                       
                            } else if (duration === 2) {
                                date = DateTime.fromISO(availability[i - 3].date);
                                dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === date.toISODate());
                                slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === date.toFormat('h:mm a'));
                            }

                            const key = `${dateIndex}-${slotIndex}`;
                            const teacherData = { uuid: slot.teacher.uuid, first_name: slot.teacher.first_name, last_name: slot.teacher.last_name };
                            
                            if (slotMap[key]) {
                                slotMap[key].push(teacherData);
                            } else {
                                slotMap[key] = [teacherData];
                            }

                            duration_count = 0;
                        } else {
                            /*
                             * duration 1 and count is not 2
                             * - first slot
                             * - second slot but count is not 2
                             *    - key = ex) 1--1 => need to make it as the first slot's key
                             * duration 2 and count is not 4
                             * - first slot
                             * - second slot
                             *    - key = ex) 1--1 => need to make it as the first slot's key
                             * - third slot
                             *    - key = ex) 1--2 => need to make it as the first slot's key
                             * - fourth slot but count is not 4
                             *    - key = ex) 1--3 => need to make it as the first slot's key
                            */
                    

                            let first_key = null;
                            let time_index = null;
                            const time = date.toFormat('h:mm a');
                            const hours = weekDates[dateIndex].hours;

                            for (let i = 0; i < hours.length; i++) { // 7:00
                                const hour_time = DateTime.fromFormat(hours[i], 'h:mm a');
                                const _time = DateTime.fromFormat(time, 'h:mm a');
                           
                                if (hour_time > _time) {
                                    time_index = i - 1;
                                    break;
                                }
                            };

                            if (time_index === -1) {
                                continue;
                            }

                            if (time_index === null) {
                                time_index = hours.length - 1;
                            }

                            first_key = `${dateIndex}-${time_index}`;
                            
                            // const key = `${dateIndex}-${slotIndex}`; // modify this key to the first slot's key
                            const teacherData = { uuid: slot.teacher.uuid, first_name: slot.teacher.first_name, last_name: slot.teacher.last_name, possible_time: DateTime.fromISO(slot.date.split("T")[1]).toFormat('h:mm a') };

                            if (possibleSlotMap[first_key]) {
                                possibleSlotMap[first_key].push(teacherData);
                            } else {
                                possibleSlotMap[first_key] = [teacherData];
                            }
                        };
                    };

                    setSelectedSlots(slotMap);
                    setPossibleSlots(possibleSlotMap);
                } else {
                    setSelectedSlots({});
                    setPossibleSlots({});
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                if (error.response?.status === 401) {
                    handleLogout();
                }
                setErrorAlertMessage(error.response?.data?.message || "An error occurred.");
                setErrorAlertOpen(true);
            }
        };

        fetchTeacherAvailability();
    }, [weekDates, targetSubjects]);

    const isNoClassSlot = (dateObj, hour) => {
        if (!dateObj || !dateObj.date) return false;
    
        const noClassStartTime = 8; // 8:00 AM
        const noClassEndTime = 15.5; // 3:30 PM (inclusive)
    
        const hourValue = DateTime.fromFormat(hour, 'h:mm a').hour + (DateTime.fromFormat(hour, 'h:mm a').minute === 30 ? 0.5 : 0);
    
        const weekday = dateObj.date.weekday;
        if (weekday === 7 || (weekday >= 1 && weekday <= 5 && hourValue >= noClassStartTime && hourValue <= noClassEndTime)) {
            return true;
        }
        return false;
    };

    function handleDateChange(date, lOrR) {
        if (lOrR === -1) {
            setDate(one_week_later);
            setDeadlineDate(one_week_ago);
            setWeekDates(generateWeekDatesForTeacherScheduler(one_week_later, duration));
        }
        else if (lOrR === 0) {
            setDate(date.minus({ days: 7 }));
            setDeadlineDate(deadlineDate.minus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.minus({ days: 7 }), duration));
        }
        else if (lOrR === 1) {
            setDate(date.plus({ days: 7 }));
            setDeadlineDate(deadlineDate.plus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.plus({ days: 7 }), duration));
        }
    };

    const handleDurationChange = (duration) => {
        setDuration(duration);
        setWeekDates(generateWeekDatesForTeacherScheduler(date, duration));
        // if (duration === 0.5) {
        //     setROWHEIGHT(30);
        // } else if (duration === 1) {
        //     setROWHEIGHT(60);
        // } else if (duration === 2) {
        //     setROWHEIGHT(120);
        // };
    };

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Modal
            aria-labelledby="time-setting-modal-title"
            aria-describedby="time-setting-modal-description"
            open={open}
            onClose={timeModalClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                        <Typography id="time-setting-modal-title" variant="h6">
                            Set Time Range
                        </Typography>
                        <Typography id="time-setting-modal-title" sx={{fontSize: '12px'}}>
                            Current Interval: {duration === 0.5 ? "30 minutes" : duration === 1 ? "1 hour" : "2 hours"}
                        </Typography>
                    </Box>
                    <Autocomplete
                        id="combo-box-demo-1"
                        getOptionLabel={(option) => option.label}
                        options={hours}
                        renderInput={(params) => <TextField {...params} label="Start Time" />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(event, value) => setStartTime(value ? value.value : "08:00")}
                        sx={{ mt: 2 }}
                        value={findHourObject(startTime)}
                    />
                    <Autocomplete
                        id="combo-box-demo-1"
                        getOptionLabel={(option) => option.label}
                        options={hours}
                        renderInput={(params) => <TextField {...params} label="End Time" />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(event, value) => setEndTime(value ? value.value : "00:00")}
                        sx={{ mt: 2 }}
                        value={findHourObject(endTime)}
                    />
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="primary" onClick={handleTimeRangeChange}>
                            Save
                        </Button>                  
                    </Box>
                </Box>
            </Fade>
        </Modal>
        <Box m="20px" marginX={3} marginY={3}>
            <Header title="Weekly Availability" subtitle="A ONE Institute" />            
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mr: 1,
                }}
            >
                {/* Container for the four buttonxs with added gap */}
                <Box 
                    sx={{ 
                        display: "flex",
                        gap: 2,
                    }}
                >
                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },
                        }}
                        onClick={handleMenuToggle}
                    >
                        Time Interval
                    </Button>
                    <Menu
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorEl={anchorEl}                                                                       
                    >
                        {durations.map((duration, index) => (
                            <MenuItem 
                                key={index}
                                onClick={() => {
                                    handleDurationChange(duration.value);
                                    handleMenuClose();
                                }}
                            >
                                {duration.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },
                        }}
                        onClick={timeModalOpen}
                    >
                        <AccessTimeFilledIcon sx={{mr: 1}}/>
                        Range
                    </Button>
                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },                            
                        }}
                        onClick={handleReset}
                    >
                        <RestartAltIcon sx={{mr: 1}}/>
                        Reset Setting
                    </Button>
                    <FormControl sx={{minWidth: '100px'}}>
                    <InputLabel id="subject-availability-label">Subjects</InputLabel>
                        <Select
                            labelId="subject-availability-label"
                            id="subject-availability"
                            multiple
                            value={targetSubjects}
                            onChange={handleSubjectChange}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {subjectCategories.map((subject, index) => (
                                <MenuItem key={index} value={subject}>
                                    <Checkbox checked={targetSubjects.indexOf(subject) > -1} />
                                    <ListItemText primary={subject} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button 
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },
                        }}
                        onClick={() => handleDateChange(date, -1)}
                    >
                        First Page
                    </Button>
                    <Button 
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },
                        }}
                        onClick={() => handleDateChange(date, 0)}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                    <Button 
                        variant='contained'
                        type='button'
                        color='primary'
                        size='small'
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey[700],
                            },
                        }}
                        onClick={() => handleDateChange(date, 1)}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                </Box>

                {/* Container for the deadline mark */}
                <Box>
                    <Typography id="modal-modal-title" variant="h4" sx={{ display: "flex", alignItems: "center", color: "red" }}>
                        Deadline for this week: {deadline_date} (EDT)
                    </Typography>
                </Box>
            </Box>
            {loading ? <Loading /> :
                <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px', marginTop: 10}}>
                    <thead>
                        <tr>
                            {weekDates.map((dateObj, dateIndex) => (
                                <th
                                    key={dateIndex}
                                    style={{
                                        position: 'sticky',
                                        top: 0,
                                        borderLeft: dateIndex === 1 ? "1px solid lightgrey" : undefined,
                                        borderRight: "1px solid lightgrey",
                                        borderBottom: "1px solid lightgrey",
                                        backgroundColor: dateObj.date?.toISODate() === DateTime.now().toISODate() ? 'green' : isDarkMode ? '#28292c' : 'lightgrey',
                                        color: isDarkMode ? 'white' : 'black',
                                        textAlign: 'center',
                                        minHeight: 30,
                                        padding: '8px',
                                    }}
                                >
                                    {dateIndex === 0 ? "Time" :
                                        <Typography variant="h6">
                                            {dateObj.date.toLocaleString({ weekday: "short" })}, {dateObj.date.toLocaleString({ month: "short" })} {dateObj.date.day}, {dateObj.date.year}
                                        </Typography>
                                    }
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {weekDates[0].hours.map((hour, hourIndex) => (
                            <tr key={hourIndex}>
                                {weekDates.map((dateObj, dateIndex) => {
                                    const key = `${dateIndex}-${hourIndex}`;
                                    const teachersInSlot = selectedSlots[key] || [];
                                    const isNoClass = isNoClassSlot(dateObj, hour);
                                    const possibleTeachers = possibleSlots[key] || [];
                                    const isPossible = possibleTeachers.filter(teacher => !teachersInSlot.find(t => t.uuid === teacher.uuid));
                                    
                                    // Group possible teachers by uuid
                                    const groupedTeachers = isPossible.reduce((acc, teacher) => {
                                        if (!acc[teacher.uuid]) {
                                            acc[teacher.uuid] = {
                                                first_name: teacher.first_name,
                                                last_name: teacher.last_name,
                                                uuid: teacher.uuid,
                                                times: [teacher.possible_time],
                                            };
                                        } else {
                                            acc[teacher.uuid].times.push(teacher.possible_time);
                                        }
                                        return acc;
                                    }, {});

                                    const hasPossibleTeachers = Object.keys(groupedTeachers).length > 0;                                

                                    return (
                                        /*
                                        case 1: teachersInSlot.length > 0 && isPossible.length === 0
                                        case 2: teachersInSlot.length === 0 && isPossible.length > 0
                                        case 3: teachersInSlot.length > 0 && isPossible.length > 0
                                        case 4: teachersInSlot.length === 0 && isPossible.length === 0
                                        */
                                        <td
                                            key={dateIndex}
                                            style={{
                                                borderLeft: dateIndex === 1 ? "1px solid lightgrey" : undefined,
                                                borderRight: "1px solid lightgrey",
                                                borderBottom: "1px solid lightgrey",
                                                padding: '8px',
                                                backgroundColor: hourIndex % 2 === 0 
                                                    ? (isDarkMode ? '#121212' : 'Azure') 
                                                    : (isDarkMode ? '#373737' : 'Beige'),
                                                verticalAlign: 'top',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {/* Display hour in the first column */}
                                            {dateIndex === 0 && (
                                                <Tooltip title={`${hour} ~ ${DateTime.fromFormat(hour, 'h:mm a').plus({ minutes: duration * 60 }).toFormat('h:mm a')}`} placement="right">
                                                    <Typography variant="h6">
                                                        {hour}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                            
                                            {/* Display teachers in slot (Case 1 & 3) */}
                                            {teachersInSlot.length > 0 ? (
                                                <Stack spacing={1}>
                                                    {teachersInSlot.map((teacher, idx) => {
                                                        return (
                                                            <TeacherChip
                                                                key={idx}
                                                                teacher={teacher}
                                                                time={date}
                                                                isDarkMode={isDarkMode}
                                                            />
                                                        );
                                                    })}
                                                </Stack>
                                            ) : (
                                                <Typography variant="body2">
                                                    {isNoClass ? 'No Class' : null}
                                                </Typography>
                                            )}

                                            {hasPossibleTeachers &&
                                                <div>
                                                    <Button
                                                        aria-describedby={popoverId(key)}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={(e) => handlePopoverOpen(e, key)}
                                                        sx={{
                                                            textTransform: 'none',
                                                            padding: '4px 8px',
                                                            fontSize: '12px',
                                                            mt: 1,
                                                        }}
                                                    >
                                                        See Possible Teachers
                                                    </Button>

                                                    <Popover
                                                        id={popoverId(key)}
                                                        open={popoverOpen(key)}
                                                        anchorEl={popoverAnchorEl[key]}
                                                        onClose={() => handlePopoverClose(key)}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        PaperProps={{
                                                            sx: {
                                                                padding: '16px',
                                                                borderRadius: '8px',
                                                                backgroundColor: isDarkMode ? '#121212' : '#f5f5f5',
                                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                                maxWidth: '300px',
                                                                wordWrap: 'break-word',
                                                            }
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            {Object.keys(groupedTeachers).map((uuid, idx) => {
                                                                const teacher = groupedTeachers[uuid];

                                                                return (
                                                                    <Box key={idx} sx={{ marginBottom: '8px' }}>
                                                                        {/* Display the teacher's chip */}
                                                                        <TeacherChip
                                                                            teacher={teacher}
                                                                            time={date}
                                                                            isDarkMode={isDarkMode}
                                                                        />
                                                                        
                                                                        {/* Display each time range on a new line */}
                                                                        <Box sx={{ marginTop: '4px' }}>
                                                                            {teacher.times.map((time, timeIdx) => {
                                                                                const startTime = DateTime.fromFormat(time, 'h:mm a');
                                                                                const endTime = startTime.plus({ minutes: 30 });
                                                                                return (
                                                                                    <Typography key={timeIdx} variant="body2" sx={{ color: isDarkMode ? 'white' : 'gray' }}>
                                                                                        {`${startTime.toFormat('h:mm a')} ~ ${endTime.toFormat('h:mm a')}`}
                                                                                    </Typography>
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    </>
)};            

export default TeacherScheduleCheck;