import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from '@mui/material/Autocomplete';
import Axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

Settings.defaultZone = "system";

const CreateCourseModal = ({
    semestersForCreateCourseModal,
    setAnchorEl, 
    setInfoUpdated,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    // for course "from date" and "to date" (optional)
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    // for course's semesters
    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const [semesterForCourse, setSemesterForCourse] = React.useState(null);

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Monday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    const [days, setDays] = React.useState(null);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(DateTime.now());
    const [toValue, setToValue] = React.useState(DateTime.now());

    const handleCreateCourse = async (values) => {
        setBackdropOpen(true);

        if (values.course_title.trim() === "" || values.subject.trim() === "") {
            setErrorAlertMessage("Course title and subject are required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (semesterForCourse === null || semesterForCourse.length === 0) {
            setErrorAlertMessage("Semester is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        const semesterIdList = semesterForCourse !== null ? semesterForCourse.map((semester) => semester.id) : [];
        let courseUUID = null;

        if (startDate === null || endDate === null) {
            setErrorAlertMessage("Please select start and end date for this course!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (startDate > endDate) {
            setErrorAlertMessage("Start date cannot be greater than end date!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        const utcStartDate = startDate.toUTC();
        const utcEndDate = endDate.toUTC();

        if (days === null || days.length === 0) {
            setErrorAlertMessage("Please select at least one day for this course!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (fromValue === null || toValue === null) {
            setErrorAlertMessage("Please select start and end time for this course!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        // check if start and end time are valid
        if (fromValue > toValue) {
            setErrorAlertMessage("\"From time\" cannot be greater than or equal to \"To time\"!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        // generate class schedule for the course using the days, fromValue, toValue, startDate, endDate
        // loop through from startDate to endDate and check if the day is in the days list
        const courseSchedule = [];

        for (let date = startDate; date <= endDate; date = date.plus({days: 1})) {
            if (days.includes(date.weekdayLong.toLowerCase())) {
                const sessionTime = {
                    start: date.set({hour: fromValue.hour, minute: fromValue.minute, second: 0}),
                    end: date.set({hour: toValue.hour, minute: toValue.minute, second: 0}),
                };

                courseSchedule.push(sessionTime);
            }
        }
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses`, {
            code: values.code ? values.code.trim() : null,
            course_title: values.course_title.trim(),
            subject: values.subject.trim(),
            textbook: values.textbook ? values.textbook.trim() : null,
            textbook_total_page: values.textbook_total_page ? values.textbook_total_page : 0,
            price: values.price ? values.price : null,
            semesterIdList: semesterIdList,
            start_date: utcStartDate,
            end_date: utcEndDate,
            allDay: false,
            courseSchedule: courseSchedule,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Course created successfully!");
            setSuccessAlertOpen(true);
            setInfoUpdated(true);
            setBackdropOpen(false);
            handleClose();
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        minHeight: 500,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Create Course</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <Formik
                                onSubmit={handleCreateCourse}
                                initialValues={initialValues}
                                validationSchema={checkoutSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                <form onSubmit={handleSubmit}>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        mb={3}
                                    >
                                        Create a course
                                    </Typography>
                                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Course Code"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.code}
                                            name="code"
                                            error={!!touched.code && !!errors.code}
                                            helperText={touched.code && errors.code}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <Box sx={{ gridColumn: "span 2" }}></Box>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Course Title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.course_title}
                                            name="course_title"
                                            error={!!touched.course_title && !!errors.course_title}
                                            helperText={touched.course_title && errors.course_title}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Subject"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.subject}
                                            name="subject"
                                            error={!!touched.subject && !!errors.subject}
                                            helperText={touched.subject && errors.subject}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Textbook"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.textbook}
                                            name="textbook"
                                            error={!!touched.textbook && !!errors.textbook}
                                            helperText={touched.textbook && errors.textbook}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Total Page of Textbook"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.textbook_total_page}
                                            name="textbook_total_page"
                                            error={!!touched.textbook_total_page && !!errors.textbook_total_page}
                                            helperText={touched.textbook_total_page && errors.textbook_total_page}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Price"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.price}
                                            name="price"
                                            error={!!touched.price && !!errors.price}
                                            helperText={touched.price && errors.price}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <Typography variant="h6" fontWeight='bold' color={colors.primary[450]} sx={{ gridColumn: "span 4" }}>
                                            Course Schedule
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <DatePicker
                                                label="From (required)"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    const startOfDate = newValue.startOf('day');
                                                    setStartDate(startOfDate);
                                                }}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <DatePicker
                                                label="To (required)"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    const endOfDate = newValue.endOf('day');
                                                    setEndDate(endOfDate);
                                                }}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                        </LocalizationProvider>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined-1"
                                            options={semestersForCreateCourseModal !== null ? semestersForCreateCourseModal : []}
                                            getOptionLabel={(semester) => semester.year + " " + seasons[semester.season - 1]}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Semester (required)"
                                                    placeholder="Select semesters"
                                                />
                                            )}
                                            sx={{ gridColumn: "span 3" }}
                                            onChange={(event, value) => setSemesterForCourse(value)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                        />
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined-1"
                                            options={daysOfWeek}
                                            getOptionLabel={(day) => day}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Days (required)"
                                                    placeholder="Select days"
                                                />
                                            )}
                                            sx={{ gridColumn: "span 3" }}
                                            onChange={(event, value) => setDays(value)}
                                        />
                                        {days !== null && days.length > 0 && (
                                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                                <TimePicker
                                                    label="From (class session time)"
                                                    value={fromValue}
                                                    onChange={(newValue) => setFromValue(newValue)}
                                                    format="HH:mm"
                                                    sx={{ gridColumn: "span 2" }}
                                                />
                                                <TimePicker
                                                    label="To"
                                                    value={toValue}
                                                    onChange={(newValue) => setToValue(newValue)}
                                                    format="HH:mm"
                                                    sx={{ gridColumn: "span 2" }}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Box>
                                    <Box display="flex" justifyContent="start" mt="20px">
                                        <Button 
                                            type="submit" 
                                            color="primary" 
                                            variant="contained" 
                                            sx={{ 
                                                mt: 1,
                                                ":hover": {
                                                    backgroundColor: colors.greenAccent[500],
                                                    color: colors.primary[400],
                                                }
                                            }}
                                        >
                                            Create New Course
                                        </Button>
                                    </Box>
                                </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

const checkoutSchema = yup.object().shape({
    code: yup.string().required(),
    course_title: yup.string().required("required"),
    subject: yup.string().required("required"),
    textbook: yup.string().optional(),
    textbook_total_page: yup.number().optional(),
    price: yup.number().optional(),
});

const initialValues = {
    code: "",
    course_title: "",
    subject: "",
    textbook: "",
    textbook_total_page: 0,
    price: "",
};

export default CreateCourseModal