import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import React from 'react';
import { useSignIn } from 'react-auth-kit';
import jwt_decode from 'jwt-decode';
import CloseIcon from '@mui/icons-material/Close';

const Signin = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const signIn = useSignIn();
    const navigateTo = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const password = data.get('password');

        if (email === null || email === undefined || email === "") {
            alert("Email is required");
            return;
        } else if (password === null || password === undefined || password === "") {
            alert("Password is required");
            return;
        }

        if (email.length > 50) {
            alert("Email must be less than 50 characters");
            return;
        } else if (password.length > 30) {
            alert("Password must be less than 30 characters");
            return;
        }

        Axios.post(`${process.env.REACT_APP_URL}/api/v1/signin`, {
            email: email,
            password: password,
        }).then((response) => {
            const userName = response.data.userName;
            const who = jwt_decode(response.data.token).who;

            signIn({
                token: response.data.token,
                expiresIn: response.data.expiresIn,
                tokenType: "Bearer",
                authState: { id: jwt_decode(response.data.token).id, email: data.get('email'), who: jwt_decode(response.data.token).who },
            });

            // 👇️ save user name in local storage for chat
            localStorage.setItem("userName", userName);
            localStorage.setItem("who", who);

            if (who === "admin")
                navigateTo('/');
            else if (who === "student" || who === "teacher")
                navigateTo('/classrooms/classes');
            
        }).catch((error) => {
            alert(error.response.data.message);
        });
    };

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div></div>
            <div>
                <Typography variant="h1" style={{ textAlign: 'center' }}>
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '24vw', padding: '1rem' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                        color='secondary'
                    >
                        <Typography color={colors.primary[400]}>Sign In</Typography>
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/forgot-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div style={{display: "flex", padding: "2rem", gap: "1rem"}}>
                <img
                    alt="ETT logo"
                    width="160px"
                    height="60px"
                    src={`../../imgs/ETT_logo_tran.png`}
                    style={{margin: "auto"}}
                />
                <CloseIcon fontSize='large' sx={{ m: "auto" }} />
                <img
                    alt="solple logo"
                    width="200px"
                    height="64px"
                    src={`../../imgs/aone-logo.png`}
                    style={{margin: "auto"}}
                />
            </div>
        </Box>
    )
}

export default Signin